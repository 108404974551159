import React,{useState} from 'react'
import { MwInputFile, MwInputText, MwSelector, MwSpinnerButton,TagList } from '../ui'
import { useFetch } from '../../hooks'
import MwTabView from '../ui/tab/MwTabView'
import MwTabViewHead from '../ui/tab/MwTabViewHead'
import MwTabViewBody from '../ui/tab/MwTabViewBody'
import TabPrice from './form/TabPrice'
import { FORM_TABS } from '../../constants/subscription'
import TabLinks from './form/TabLinks'
import TabSettings from './form/TabSettings'
import useUploadFile from '../../hooks/useUploadFile'
import ActivitiesBar from './form/ActivitiesBar'

const SubscriptionForm = ({
    errors,
    formState,
    setFormState,
    activitySelected,
    setActivitySelected,
    periodMonthSelected,
    setPeriodMonthSelected,
    branchesSelling,
    setBranchesSelling,
    branchesProviding,
    setBranchesProviding,
    serviceTypeSelected,
    setServiceTypeSelected,
    serviceClassSelected,
    setServiceClassSelected,
    servicesTagList,
    setServicesTagList,
    currentLangId,
    currentBranches,
    activities,
    activitiesLoading,
    servicesTypesResult,
    servicesTypesListLoading,
    servicesClassesResult,
    servicesClassesListLoading,
    subscriptionDays,
    setSubscriptionDays,
    AllowStopSubscription,
    setAllowStopSubscription,
    AllowWaiverSubscription,
    setAllowWaiverSubscription,
    AllowAddPeriodSubscription,
    setAllowAddPeriodSubscription,
    AllowRefundSubscription,
    setAllowRefundPeriodSubscription,
    selectedImage,
    loadingUploadPic,
    handleImageUpload
}) => {
    

    const [tabActive,setTabActive] = useState('tabPrice');
    console.log('activities',activities)
    return (
        <div className='flex flex-col gap-3'>
            <div className="flex flex-col gap-3 justify-between">
                <div className='flex-1 flex gap-1'>
                    <div className='w-28'>
                        <MwInputText
                            label ='رقم الاشتراك'
                            disabled={true}
                            value={formState.subscriptionNo}
                            onChange={(e) => setFormState((state)=> ({...state,subscriptionNo:e.target.value}) )}
                            invalid = {!!errors?.subscriptionNo}
                            invalidMsg = {errors?.subscriptionNo}
                            />
                    </div>
                    
                    <div className='flex-1 gap-3'>
                    <TagList
                        withLabel={true}
                        label={`تاج / علامة`}
                        tagList={servicesTagList}
                        setTagList={setServicesTagList}
                        />
                    </div>
                </div>
                <div className='flex gap-1'>
                    <div className='flex-1'>
                        <MwInputText
                            label =' اسم الاشتراك (عربي)'
                            value={formState.nameAr}
                            onChange={(e) => setFormState((state)=> ({...state,nameAr:e.target.value}) )}
                            invalid = {!!errors?.nameAr}
                            invalidMsg = {errors?.nameAr}
                            />
                    </div>
                    <div className='flex-1'>
                        <MwInputText
                            label =' اسم الاشتراك (English)'
                            value={formState.nameEn}
                            onChange={(e) => setFormState((state)=> ({...state,nameEn:e.target.value}) )}
                            invalid = {!!errors?.nameEn}
                            invalidMsg = {errors?.nameEn}
                            />
                    </div>
                </div>
                <div className='flex-1'>
                        <MwInputText
                            label ='ملاحظات'
                            value={formState.notes}
                            onChange={(e) => setFormState((state)=> ({...state,notes:e.target.value}) )}
                            invalid = {!!errors?.notes}
                            invalidMsg = {errors?.notes}
                            />
                    </div>
                <div className='flex gap-3 justify-between'>
                    <div className='flex-1'>
                        <MwTabView>
                            <MwTabViewHead tabActive={tabActive} setTabActive={setTabActive} tabTitleList={FORM_TABS} />
                                <MwTabViewBody>
                                    { tabActive === 'tabPrice' && <TabPrice 
                                        errors ={errors} 
                                        formState= {formState} 
                                        setFormState={setFormState}
                                        periodMonthSelected = {periodMonthSelected} 
                                        setPeriodMonthSelected = {setPeriodMonthSelected}
                                        
                                        /> }
                                    { tabActive === 'tabSettings' && <TabSettings 
                                        errors ={errors} 
                                        formState= {formState} 
                                        setFormState={setFormState}
                                        subscriptionDays = {subscriptionDays}
                                        setSubscriptionDays = {setSubscriptionDays}
                                        AllowStopSubscription = {AllowStopSubscription}
                                        setAllowStopSubscription = {setAllowStopSubscription}
                                        AllowWaiverSubscription = {AllowWaiverSubscription}
                                        setAllowWaiverSubscription = {setAllowWaiverSubscription}
                                        AllowAddPeriodSubscription = {AllowAddPeriodSubscription}
                                        setAllowAddPeriodSubscription = {setAllowAddPeriodSubscription}
                                        AllowRefundSubscription = {AllowRefundSubscription}
                                        setAllowRefundPeriodSubscription = {setAllowRefundPeriodSubscription}
                                        /> }
                                    { tabActive === 'tabLinks' && <TabLinks 
                                        serviceTypeSelected = {serviceTypeSelected}
                                        setServiceTypeSelected = {setServiceTypeSelected}
                                        serviceClassSelected = {serviceClassSelected}
                                        setServiceClassSelected = {setServiceClassSelected}
                                        currentLangId ={currentLangId}
                                        errors ={errors} 
                                        formState= {formState} 
                                        setFormState={setFormState} 
                                        branchesSelling = {branchesSelling}
                                        setBranchesSelling = {setBranchesSelling}
                                        branchesProviding = {branchesProviding}
                                        setBranchesProviding = {setBranchesProviding}
                                        currentBranches ={currentBranches}
                                        activitiesLoading = {activitiesLoading}
                                        servicesTypesResult = {servicesTypesResult}
                                        servicesTypesListLoading = {servicesTypesListLoading}
                                        servicesClassesResult = {servicesClassesResult}
                                        servicesClassesListLoading = {servicesClassesListLoading}
                                        /> }
                                    
                                </MwTabViewBody>
                        </MwTabView>
                        <hr className='mx-3 mt-5' />
                        { activitiesLoading ? <MwSpinnerButton label='جاري جلب الانشطة...' withLabel={true} />  : 
                        <ActivitiesBar 
                            activities = {activities}
                            activitySelected = {activitySelected}
                            setActivitySelected = {setActivitySelected}
                            />
                        }
                    </div>
                    <div className='w-64 mt-5 '>
                        <img 
                            src={ !!selectedImage ? selectedImage :'/assets/images/serv-logo.jpg'} 
                            title='Employee Image'
                            className='rounded-lg'  
                            /> 
                        <MwInputFile
                            loading={loadingUploadPic} 
                            onChangeHandler={handleImageUpload}
                            /> 
                    </div>
                </div>
               
            </div>

           
        </div>
    )
}

export default SubscriptionForm