export const idTypesList = [
    {
        label: 'Iqama',
        value: 'Iqama',
        lang:'en'
    },
    {
        label: 'أقامة',
        value: 'Iqama',
        lang:'ar'
    },
    {
        label: 'National identity',
        value: 'National',
        lang:'en'
    },
    {
        label: 'هوية وطنيية',
        value: 'National',
        lang:'ar'
    },
    {
        label: 'Passport',
        value: 'National',
        lang:'en'
    },
    {
        label: 'جواز السفر',
        value: 'National',
        lang:'ar'
    }
];

export const genderList = [
    {
        label: 'Male',
        value: 'Male',
        lang:'en'
    },
    {
        label: 'ذكر',
        value: 'Male',
        lang:'ar'
    },
    {
        label: 'Female',
        value: 'Female',
        lang:'en'
    },
    {
        label: 'أنثي',
        value: 'Female',
        lang:'ar'
    },
];