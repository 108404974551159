import React from 'react'
import { MwInputText, MwSelector, MwSpinnerButton, TagList } from '../../ui'

const FormHeaderMembership = ({
    formState,
    setFormState,
    errors,
    membershipTagList,
    setMembershipTagList,
    membershipGroupsList,
    membershipGroupsListLoading,
    currentLangId,
    groupSelected,
    setGroupSelected,
    statusSelected,
    setStatusSelected,
    membershipStatusResult,
    membershipStatusLoading

}) => {
    return (
        <>
            <div className='flex gap-3 mb-3'>
                <div className='w-28'>
                    <MwInputText
                        label ='رقم العضوية'
                        value={formState.membershipNo}
                        onChange={(e) => setFormState((state)=> ({...state,membershipNo:e.target.value}) )}
                        invalid = {!!errors?.membershipNo}
                        invalidMsg = {errors?.membershipNo}
                        disabled={true}
                        />
                </div>
                <div className='flex-1'>
                    <MwInputText
                        label =' اسم العضو (عربي)'
                        value={formState.nameAr}
                        onChange={(e) => setFormState((state)=> ({...state,nameAr:e.target.value}) )}
                        invalid = {!!errors?.nameAr}
                        invalidMsg = {errors?.nameAr}
                        />
                </div>
                <div className='flex-1'>
                    <MwInputText
                        label =' اسم العضو (English)'
                        value={formState.nameEn}
                        onChange={(e) => setFormState((state)=> ({...state,nameEn:e.target.value}) )}
                        invalid = {!!errors?.nameEn}
                        invalidMsg = {errors?.nameEn}
                        />
                </div>
                <div className='w-48'>
                { membershipGroupsListLoading ? <MwSpinnerButton withLabel={false}/> : <MwSelector
                        label={`مجموعة العضو`}
                        initalValue='أختار مجموعة العضو...'
                        _data={membershipGroupsList?.map(group =>({
                            label: group[`name_${currentLangId}`] ,
                            value: group?.id,
                            lang:currentLangId
                        }))}
                        dataType={'labelValue'}
                        selectedItem={groupSelected}
                        setSelectedItem={setGroupSelected}
                        withAddNew={false}
                        invalid={!!errors?.groupSelected}
                        invalidMsg={errors?.groupSelected}
                        />
                    }
                </div>
                <div className='w-28 '>
                    { membershipStatusLoading ? <MwSpinnerButton withLabel={false}/> : <MwSelector
                        label={`حالة العضو`}
                        initalValue='نشط'
                        _data={membershipStatusResult?.map(status =>({
                            label: status[`name_${currentLangId}`] ,
                            value: status?.id,
                            lang:currentLangId
                        }))}
                        withSearch= {false}
                        dataType={'labelValue'}
                        selectedItem={statusSelected}
                        setSelectedItem={setStatusSelected}
                        withAddNew={false}
                        invalid={!!errors?.statusSelected}
                        invalidMsg={errors?.statusSelected}
                        />
                    }
                </div>
                            
            </div>
            <div className='fex gap-3'>
                <TagList
                    tagList={membershipTagList}
                    setTagList={setMembershipTagList}
                    />
            </div>
        </>
    )
}

export default FormHeaderMembership