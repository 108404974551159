import React from 'react'
import { MwButton,MwSpinnerButton,NoDataFound } from '../ui'
import { DataListView,DataListViewHead,DataListViewBody } from '../ui/data-view'
import MembershipsSubscriptionsListItem from './MembershipsSubscriptionsListItem'

const MembershipsSubscriptionsList = ({setMode,resultSubscription,loadingSubscription}) => {
    return (
        <div>
        <div className='my-3 flex justify-end'>
            <MwButton onClick={()=> setMode('Adding')} type='saveBtn'  >اضف اشتراك جديد</MwButton>
        </div>
        {
            loadingSubscription ? <MwSpinnerButton label='جاري جلب اشتراكات العضو...' /> :
            resultSubscription?.data?.length === 0 ? <NoDataFound msg={'لا يوجد اشتراكات لهذا العضو'} /> :
            <DataListView>
                <DataListViewHead>
                    {/* <div className='w-16 text-center'> # </div> */}
                    <div className='w-20 text-center'>رقم الاشتراك</div>
                    <div className='w-60'>اسم الاشتراك</div>
                    <div className='w-28'>بداية الاشتراك</div>
                    <div className='w-28'>نهاية الاشتراك</div>
                    {/* <div>مدة الاشتراك</div> */}
                    <div className='w-28 text-center'>حالة الاشتراك</div>
                    <div className='flex-1'> الاجراءات </div>
                </DataListViewHead>
                <DataListViewBody>
                    {
                        resultSubscription.data.map(item =>  <MembershipsSubscriptionsListItem key={item.id} item={item} />)
                    }
                </DataListViewBody>
            </DataListView>
        }
    </div>
    )
}

export default MembershipsSubscriptionsList