import React from 'react'
import MembershipListItem from './MembershipListItem'
import {  MwButton, MwSpinnerButton, NoDataFound } from '../ui'
import {  useNavigate } from 'react-router-dom'
import parse from 'html-react-parser';
import {DataListViewBody,DataListView,DataListViewHead} from '../ui/data-view/';


export const MembershipsList = ({
    data,
    loading,
    currentLangId,
    params,
    getMembershipsHandler,
    isShowDate = true,
    isShowStatus = true,
    setMode,
    setSelectedIitem,
    target
}) => {
    const navigate = useNavigate()
    return (
        <div className='h-full'>
            {
                loading ? <MwSpinnerButton label='جاري جلب الاعضاء...'  /> :
                data?.data?.length > 0 ?
                <DataListView>
                    <DataListViewHead>
                        {/* <div className='w-8 text-center'>#</div> */}
                        <div className='w-20 text-center'>رقم العضوية</div>
                        <div className='flex-1'>اسم العضو</div>
                        <div className='w-32 text-center'>رقم الجوال</div>
                        {target === 'subscriptions' && <div className='w-28 text-center'>اشتراكات العضو</div> }
                        {isShowDate && <div className='w-32 text-center'>تاريخ الإنشاء</div> }
                        {isShowStatus && <div className='w-20 text-center'>حالة العضو</div>}
                    </DataListViewHead>
                    <DataListViewBody> 
                        {
                            data?.data?.map((item,index) => (
                            <MembershipListItem 
                                currentLangId={currentLangId} 
                                key={item.id} 
                                item={item} 
                                index={index} 
                                isShowDate = {isShowDate} 
                                isShowStatus = {isShowStatus}
                                setMode = {setMode}
                                setSelectedIitem={setSelectedIitem}
                                target = {target}
                                />) )
                        }
                    </DataListViewBody>
                    { data?.links.length >0 &&
                        <div id="dataLinks" className="list-none flex gap-3 border-t py-3 px-5 cursor-default">
                        {
                            data?.links.length > 3 && 
                            data?.links.map((item,i) => <li key={i} className='hover:font-bold' onClick={()=>item?.url && getMembershipsHandler(item?.url.replace('http','https'))} >{ parse(item.label)}</li>)
                        }
                        </div> 
                    }
                </DataListView>:
                <NoDataFound msg={`لا يوجد عضويات `} > 
                    <div className='flex gap-3  justify-center items-center'>
                        <MwButton onClick={()=>navigate(`/${params.tenant}/memberships/add`)} type='saveBtn'>أنشاء عضو جديد</MwButton> 
                    </div>
                </NoDataFound>
            }
        </div>
    )
}
