import React, { useEffect, useState } from 'react'
import { CardHeaderWithActions, ConfirmDelete, MwButton } from '../../components/ui'
import { useNavigate, useParams } from 'react-router-dom';
import { useCurrent, useDelete, useFetch } from '../../hooks';
import SubscriptionsList from '../../components/Subscription/SubscriptionsList';

const ManageSubscriptions = () => {

    const navigate = useNavigate();
    const params = useParams();
    const [selectedItem,setSelectedItem] = useState();
    const [showDeleteConfirm,setShowDeleteConfirm] = useState(false);
    const {currentTenantId,currentCompanyId,currentBranchId,currentBranches,currentLangId,userId} = useCurrent();
    const {data:resultSubscription,loading:loadingSubscription,refreshHandler} = useFetch(`${process.env.REACT_APP_API_BASE_URL}/subscriptions/${currentTenantId}/${currentCompanyId}/${currentBranchId}`)
    const {data:resultDelete,loading:loadingDelete,deleteItem} = useDelete()


    useEffect(() => {
        if(resultSubscription){
            console.log('resultSubscription => ',resultSubscription)
        }
    },[resultSubscription])

    useEffect(() => {
        if(resultDelete){
            setShowDeleteConfirm(false)
            refreshHandler();
        }
    },[resultDelete]);

    const deleteHandler = () => {
        console.log('deleteHandler => ',selectedItem)
        deleteItem(`${process.env.REACT_APP_API_BASE_URL}/subscriptions/${selectedItem.id}`)
    }
    return (
        <div className='p-5'>
            <CardHeaderWithActions title={'إدارة الاشتراكات'}>
                <MwButton onClick={()=>navigate(`/${params.tenant}/subscriptions/add`)} type='secondary'>أضف اشتراك جديد</MwButton>
            </CardHeaderWithActions>
            <ConfirmDelete 
                onDeleteHandler={deleteHandler} 
                loading={loadingDelete}
                title={'حذف الاشتراك'} 
                msg={'هل انت متاكد من حذف الاشتراك'} 
                onShow={showDeleteConfirm} 
                setOnShow={setShowDeleteConfirm}
                />
            <SubscriptionsList 
                data={resultSubscription} 
                setSelectedItem={setSelectedItem}  
                setShowDeleteConfirm={setShowDeleteConfirm} 
                loading={loadingSubscription}
                />
            
        </div>
    )
}

export default ManageSubscriptions