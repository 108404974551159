import React,{useEffect} from 'react'
import { MwSwitch, TitleGroup,MwInputText } from '../../ui'

const TabSettings = ({
    formState,
    setFormState,
    errors,
    subscriptionDays,
    setSubscriptionDays,
    AllowStopSubscription,
    setAllowStopSubscription,
    AllowWaiverSubscription,
    setAllowWaiverSubscription,
    AllowAddPeriodSubscription,
    setAllowAddPeriodSubscription,
    AllowRefundSubscription,
    setAllowRefundPeriodSubscription
}) => {
    const onSwitchHandler = (name) => {
        let newSubscriptionDays = subscriptionDays;
        
        if(subscriptionDays.includes(name) || subscriptionDays.length === 7 ) {
            if(name === 'all'){
                newSubscriptionDays = [];
            }else{
                newSubscriptionDays = subscriptionDays.filter(s => s !== name);
            }
        }else{
            if(name === 'all'){
                newSubscriptionDays = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
            }else{

                newSubscriptionDays.push(name);
            }
        }
        setSubscriptionDays([...newSubscriptionDays])
        console.log('subscriptionDays=>',subscriptionDays)
    }
    useEffect(() => {
        console.log(AllowStopSubscription);
    },[AllowStopSubscription])
    return (
        <div className='flex  justify-between  gap-7 p-3'>
            
            <div className='w-96 '>
                <TitleGroup>خصائص الخدمة</TitleGroup>
                <div className='flex flex-col gap-2 '>
                    <MwSwitch withIcon={true} isActive={AllowStopSubscription} setIsActive={setAllowStopSubscription}  label={'السماح بإيقاف الخدمة'} />
                    <MwSwitch withIcon={true} isActive={AllowWaiverSubscription} setIsActive={setAllowWaiverSubscription} label={'السماح بالتنازل'} />
                    <MwSwitch withIcon={true} isActive={AllowAddPeriodSubscription} setIsActive={setAllowAddPeriodSubscription} label={'السماح بإضافة مدة'} />
                    <MwSwitch withIcon={true} isActive={AllowRefundSubscription} setIsActive={setAllowRefundPeriodSubscription} label={'السماح بالإسترجاع'} />
                </div>
                
                <div className='flex gap-2 border-t pt-3'>
                    <div className=' flex-1'>
                        <MwInputText
                            inputType = 'number'
                            label ='عدد مرات الايقاف'
                            value={formState.suspendTimes}
                            onChange={(e) => setFormState((state)=> ({...state,suspendTimes:e.target.value}) )}
                            invalid = {!!errors?.suspendTimes}
                            invalidMsg = {errors?.suspendTimes}
                            />
                    </div>
                    <div className=' flex-1'>
                        <MwInputText
                            inputType = 'number'
                            label ='عدد ايام الايقاف'
                            value={formState.suspendDays}
                            onChange={(e) => setFormState((state)=> ({...state,suspendDays:e.target.value}) )}
                            invalid = {!!errors?.suspendDays}
                            invalidMsg = {errors?.suspendDays}
                            />
                    </div>
                    <div className=' flex-1'>
                        <MwInputText
                            inputType = 'number'
                            label =' سعر الايقاف'
                            value={formState.suspendPrice}
                            onChange={(e) => setFormState((state)=> ({...state,suspendPrice:e.target.value}) )}
                            invalid = {!!errors?.suspendPrice}
                            invalidMsg = {errors?.suspendPrice}
                            />
                    </div>
                </div>
            </div>
            <div className='w-80'>
                <div className='flex justify-between'>
                    <TitleGroup>الايام المتاحة فيها الخدمة</TitleGroup>
                    <MwSwitch withIcon={true} isActive={ subscriptionDays.length === 7 } setIsActive={()=>onSwitchHandler('all') } /> 
                </div>
                    <div className='flex flex-col gap-2'>
                        <MwSwitch withIcon={true} isActive={ subscriptionDays.includes('sun') } setIsActive={()=>onSwitchHandler('sun') }  label={'الأحد'} />     
                        <MwSwitch withIcon={true} isActive={ subscriptionDays.includes('mon') } setIsActive={()=>onSwitchHandler('mon') }  label={'الاثنين'} />
                        <MwSwitch withIcon={true} isActive={ subscriptionDays.includes('tue') } setIsActive={()=>onSwitchHandler('tue') } label={'الثلاثاء'} />
                        <MwSwitch withIcon={true} isActive={ subscriptionDays.includes('wed') } setIsActive={()=>onSwitchHandler('wed') }  label={'الاربعاء'} />
                        <MwSwitch withIcon={true} isActive={ subscriptionDays.includes('thu') } setIsActive={()=>onSwitchHandler('thu') }  label={'الخميس'} />
                        <MwSwitch withIcon={true} isActive={ subscriptionDays.includes('fri') } setIsActive={()=>onSwitchHandler('fri') }  label={'الجمعة'} />
                        <MwSwitch withIcon={true} isActive={ subscriptionDays.includes('sat') } setIsActive={()=>onSwitchHandler('sat') } label={'السبت'} />
                        
                    </div>
            </div>

        </div>
    )
}

export default TabSettings