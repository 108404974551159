import React from 'react'

const MwInputText = (props) => {
    const {id,value='',defaultValue,onChange,readOnly,disabled,label,invalid,invalidMsg,placeholder,size,onKeyDown,classNameInput,inputRef,inputType='text'} = props
    return (
        <div className='w-full'>
            { label && 
                <label className='text-xs px-1 text-slate-400 pb-1' htmlFor="inv_no">{label}</label>}
            
            { inputRef ?
            <input 
                ref={inputRef}
                type={inputType}
                className= {`flex-1 text-xs text-slate-600 border w-full rounded font-bold px-2 text-center ${size ==='sm' ? ' h-6' : 'h-8' } ${classNameInput} ${ invalid ? ' border-red-500' : 'border-slate-200'} `}
                readOnly={readOnly}
                disabled={disabled}
                placeholder={placeholder}
                defaultValue={defaultValue} 
            />
            :<input 
                type={inputType}
                className= {`flex-1 text-xs text-slate-600 font-bold border   w-full rounded  px-2 text-center ${size ==='sm' ? ' h-6' : 'h-8' } ${classNameInput} ${ invalid ? ' border-red-500' : ' border-slate-200'} ${ disabled && 'border' } `}
                // id={id}
                name={id} 
                
                value={value}  
                onChange={onChange} 
                onKeyDown={onKeyDown}
                readOnly={readOnly}
                disabled={disabled}
                placeholder={placeholder}
                />}
            { invalidMsg && <div className='text-xs text-red-500'>{invalidMsg}</div> }
        </div>
    )
}

export default MwInputText