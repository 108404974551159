import { useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';

const TokenRedirect = () => {

    const {tenant,token} = useParams();
    const navigate = useNavigate();
    const localToken = localStorage.getItem('token')

    const tokenHandler = async() => {
        
        localStorage.setItem('token', token);

        navigate(`/${tenant}/dashboard`);
    }

    useEffect(() => {
        console.log('localToken correct:',localToken,token);
        if(localToken){
            
            if(localToken !== token){
                console.log('localToken:',localToken,token);
                localStorage.clear();
                localStorage.setItem('token', token);
                navigate(0)
            }else{
                tokenHandler();
            }
        }else{
            tokenHandler();
        }
       // localStorage.clear();

        // setTimeout(() => {
        //         tokenHandler();
        // }, 1500);

    },[]);
    
    return (
        <div className='p-5 flex items-center justify-center text-slate-500'>REDIRECT TO SALES DASHBORD...</div>
    )

}

export default TokenRedirect