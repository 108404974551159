import React, { useEffect,useState } from 'react'
import { MembershipsList } from '../../components/memberships/MembershipsList'
import { usePost,useCurrent,useFetch } from '../../hooks';
import { useParams } from 'react-router-dom';
import MembershipsFilter from '../../components/memberships/MembershipsFilter';
import MembershipSearchItem from '../../components/memberships/MembershipSearchItem';
import { CardHeaderWithActions, MwButton } from '../../components/ui';
import { IoMdPersonAdd } from "react-icons/io";

const MembershipsManagement = () => {
    const params = useParams();
    const {currentTenantId,currentCompanyId,currentBranchId,currentLangId} = useCurrent();
    const {data:membershipsResult,loading:membershipsLoading,postData:getMemberships} = usePost();
    
    /// get Membership Groups 
    const {data:membershipGroupsResult,loading:membershipGroupsLoading} = useFetch(`${process.env.REACT_APP_API_BASE_URL}/memberships-groups/${currentTenantId}/${currentCompanyId}/${currentBranchId}`)
    /// get Membership Status 
    const {data:membershipStatusResult,loading:membershipStatusLoading} = useFetch(`${process.env.REACT_APP_API_BASE_URL}/memberships-status`)
    /// get Nationlaltiy  
    const {data:nationalityResult,loading:nationalityLoading} = useFetch(`${process.env.REACT_APP_API_BASE_URL}/countries`)
    const [filterState,setFilterState] = useState({
        startDate: '',
        endDate:''
    })
    const [tagsList,setTagsList] = useState([])
    const [nationalitySelected,setNationalitySelected] = useState(null);
    const [statusSelected,setStatusSelected] = useState({
        label:currentLangId === 'en' ? 'New' : 'جديد',
        value: 1,
        lang:currentLangId
    });
    const [groupSelected,setGroupSelected] = useState({
        label:currentLangId === 'en' ? 'Member' : 'أعضاء النادي',
        value: 1,
        lang:currentLangId
    });
    const [term,setTerm] = useState('');
    const filterParams ={
        limit: 25,
        tenant_id: currentTenantId,
        company_id: currentCompanyId,
        branch_id: currentBranchId,
    };
    


    const getMembershipsHandler = (_filterParams=filterParams,url = `${process.env.REACT_APP_API_BASE_URL}/memberships/filterBy`  ) =>{
        getMemberships(url,_filterParams);
    }

    const filterHandler = () => {
        const _dataFilter = {
            limit: 25,
            tenant_id: currentTenantId,
            company_id: currentCompanyId,
            branch_id: currentBranchId,
            start_date: filterState?.startDate || null,
            end_date: filterState?.endDate || null,
            status_id: statusSelected?.value  || null,
            group_id: groupSelected?.value || null,
            nationaltiy_id: nationalitySelected?.value || null,
            term: term || null,
        }
        getMembershipsHandler(_dataFilter);
    }
    const searchHandler = () => {
        const _dataFilter = {
            limit: 25,
            tenant_id: currentTenantId,
            company_id: currentCompanyId,
            branch_id: currentBranchId,
            term: term || null,
        }
        getMembershipsHandler(_dataFilter);
    }

    useEffect(() => {
        getMembershipsHandler();
    },[]);
    
    useEffect(()=>{
        if(membershipsResult){
            console.log(membershipsResult);
        }
    },[membershipsResult]);

    return (
        <div className='p-5'>
            <CardHeaderWithActions title={`ادارة الاعضاء`}>
                <MwButton type='secondary'>
                    <IoMdPersonAdd size={18} />
                    <span>أضف عضوية جديدة</span>
                </MwButton>
            </CardHeaderWithActions>
            <div className='flex gap-3  overflow-auto '>
                <div className='flex-1'>
                    <MembershipsList params={params} currentLangId={currentLangId} data={membershipsResult} loading={membershipsLoading} getMembershipsHandler={getMembershipsHandler}/>
                </div>
                <div className='w-64'>
                    <MembershipSearchItem term ={term} setTerm={setTerm}  cbSearch={searchHandler}/>
                    
                    <MembershipsFilter
                        currentLangId = {currentLangId}
                        filterState = {filterState}
                        setFilterState ={setFilterState}
                        tagsList = {tagsList}
                        setTagsList = {setTagsList}
                        membershipGroupsResult = {membershipGroupsResult}
                        membershipGroupsLoading ={membershipGroupsLoading}
                        membershipStatusResult = {membershipStatusResult}
                        membershipStatusLoading = {membershipStatusLoading}
                        nationalityResult = {nationalityResult}
                        nationalityLoading = {nationalityLoading}
                        nationalitySelected = {nationalitySelected}
                        setNationalitySelected = {setNationalitySelected}
                        statusSelected = {statusSelected}
                        setStatusSelected = {setStatusSelected}
                        groupSelected = {groupSelected}
                        setGroupSelected = {setGroupSelected}
                        filterHandler = {filterHandler}
                        />
                </div>
            </div>
        </div>
    )
}

export default MembershipsManagement