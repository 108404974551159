import React,{ useState} from 'react'
import {FiSettings} from 'react-icons/fi'
import MeunSideSetting from '../components/settings/MeunSideSetting'
import { NoPermission } from '../components/ui'
import { useCurrent,  } from '../hooks'

const Settings = () => {

    const [activeMenu,setActiveMenu] = useState('SkillsTypes')
    const [loading,setLoading] = useState(false);
    const {user} = useCurrent();

    return (
        <div className='p-5'>
            <div className='flex justify-between items-center pb-5 pt-2'>
                <div className=' flex gap-1  text-sm text-gray-400 '>
                    <FiSettings size={18} /> 
                    إعدادات النظام 
                </div>
            </div>
            <div className='flex gap-2 mb-5'>
                <MeunSideSetting activeMenu = {activeMenu} setActiveMenu = {setActiveMenu}/>
                {
                    !loading &&
                    <div className='flex-1 bg-slate-50 rounded-lg p-3 overflow-auto h-[calc(100vh-200px)] '>
                        {/* {   activeMenu === 'SkillsTypes'  && <SkillTypes />}
                        {
                            (
                                activeMenu !== 'SkillsTypes' 
                            ) && 
                            <NoPermission msg={`ليس لديك صلاحية للدخول لهذة الصفحة`} />    
                        } */}
                    </div> 
                }
            </div>
        </div>
    )

}

export default Settings