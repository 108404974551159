import {tenant} from '../utils/tenantManager'
import { useSelector } from 'react-redux'

const useCurrent = () => {
    const currentTenantId = tenant()?._id;

    const currentLangList = tenant()?.languages;

    const currentCompanyId = useSelector(state => state.currentCompany).value

    const currentCompany = tenant()?.companies?.filter(company => company._id === currentCompanyId)[0]
    
    const currentBranches =  currentCompany?.branches;

    const currentBranchId = useSelector(state => state.currentBranch).value 

    const currentBranch = useSelector(state => state.currentBranch).data

    const user = useSelector(state => state.auth).user
    const userId = user?._id 
    const userName = user?.name 


    const currentLangId = useSelector(state => state.lang).value

    const currentSettings = useSelector(state => state.settings);

    return {currentTenantId,currentCompany,currentCompanyId,currentBranches,currentBranchId,currentBranch,user,userId,userName,currentLangList,currentLangId,currentSettings}
}

export default useCurrent