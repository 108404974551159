
import React from 'react'
import { MwSpinnerButton, NoDataFound } from '../ui'
import SubScriptionsListItem from './SubScriptionsListItem'

const SubscriptionsList = ({data,loading,setSelectedItem,setShowDeleteConfirm}) => {
    return (
        <div className='h-[calc(100vh-220px)] overflow-auto'>
            {
                loading ? <MwSpinnerButton label='جاري جلب الاشتراكات...' /> :
                data?.data?.data.length > 0 ? <div className='grid grid-cols-4 gap-3'>
                    {
                        data?.data?.data.map(item => <SubScriptionsListItem key={item.id}  item={item} setSelectedItem={setSelectedItem} setShowDeleteConfirm={setShowDeleteConfirm} />)
                    }
                </div> : <NoDataFound msg={'لا يوجد اشتراكات '} />
            }
        </div>
    )
}

export default SubscriptionsList