import React,{useEffect} from 'react'
import { useCurrent, useFetch, usePost } from '../../hooks'
import { useState } from 'react';

import MembershipsSubscriptionsList from './MembershipsSubscriptionsList';
import SubscriptionMembershipForm from './SubscriptionMembershipForm';
import { MwSpinnerButton } from '../ui';

const MembershipsSubscriptions = ({membershipId,membershipNo}) => {
    const _date = new Date()
    const _today = _date.toISOString().split('T')[0]
    const [mode,setMode] = useState('Listing');
    const [errors,setErrors] = useState();
    const [selectedSubscription,setSelectedSubscription] = useState()
    const [startDate,setStartDate] = useState(_today);
    const [endDate,setEndDate] = useState();
    const {currentTenantId,currentCompanyId,currentBranchId} = useCurrent();
    const {data:resultSubscription,loading:loadingSubscription,refreshHandler} = useFetch(`${process.env.REACT_APP_API_BASE_URL}/membership-subscriptions/${currentTenantId}/${currentCompanyId}/${currentBranchId}/${membershipId}`)
    const {data:resultPost,loading:loadingPost,postData} = usePost()

    useEffect(() =>{
        if(resultSubscription){
            
        }
    },[resultSubscription]);

    useEffect(() =>{
        if(resultPost){
            setMode('Listing')
            refreshHandler()
        }
    },[resultPost]);

    const validateForm = () => {
        setErrors({});
        let _errors = {};
        if(!selectedSubscription?.value){
            _errors  = {..._errors,selectedSubscription:'يجب اختيار الاشتراك'};
            setErrors({..._errors})
        }

        if(_errors && Object.keys(_errors).length > 0 ){
            console.error(_errors);
            return true;
        }
        return false;
    }
    const onSubmitHandler = () => {
        if(validateForm()){
            return;
        }
        const _data = {
            membership_id: membershipId,
            membership_no: membershipNo,
            subscription_id: selectedSubscription.value,
            start_date: startDate,
            end_date: endDate,
            price: selectedSubscription.data.price,
            total_price: selectedSubscription.data.price,
            branch_id : currentBranchId,
            company_id : currentCompanyId,
            tenant_id : currentTenantId,
        }
        console.log(_data)
        postData(`${process.env.REACT_APP_API_BASE_URL}/membership-subscriptions`,_data)
        
    }

    return (
        <>
            {
                mode === 'Adding' && (loadingPost ? <MwSpinnerButton label='جاري اعتماد الاشتراك'/> : 
                                    <SubscriptionMembershipForm 
                                        mode={mode} 
                                        setMode={setMode} 
                                        onSubmitHandler = {onSubmitHandler}
                                        errors = {errors}
                                        selectedSubscription = {selectedSubscription}
                                        setSelectedSubscription = {setSelectedSubscription}
                                        startDate ={startDate}
                                        setStartDate = {setStartDate}
                                        endDate = {endDate}
                                        setEndDate = {setEndDate}
                                        />)
            }
            {
                mode === 'Listing'  &&  <MembershipsSubscriptionsList 
                                        setMode= {setMode} 
                                        resultSubscription={resultSubscription} 
                                        loadingSubscription={loadingSubscription}
                                        />

            }
        </>
    )
}

export default MembershipsSubscriptions