import React from 'react'

const ActivitiesBar = ({activities,activitySelected,setActivitySelected}) => {
    
    const selectAllHandler = (e) =>{
        let _ids =[];
        if(e.target.checked){
            activities.forEach(element => {
                _ids.push(element.id);
            });
        }
        setActivitySelected(_ids);
    }

    const onchangeHanndler = (id) => {
        let _ids = activitySelected;
        if (activitySelected.includes(id)){
            _ids = activitySelected.filter(element => element !== id)
        }else{
            _ids = [..._ids,id];
        }
        setActivitySelected(_ids);
    }

    return (
        <div className='flex justify-center items-center gap-3 p-3 mb-5 '>
            <div className='mx-7 flex flex-col items-center'>
                <img src='/assets/icons/icons8-all-64.png' alt='all' className='w-10' />
                <div className='text-slate-500 flex gap-1'>
                    <input type='checkbox' id='checkAll'  onChange={(e)=> selectAllHandler(e)} className='' /> 
                    <label htmlFor="checkAll"  >تحديد الكل </label>
                </div>
            </div>
            {
                activities?.length > 0 && activities.map(activity => ( 
                <div key={activity.id} className='mx-7 flex flex-col items-center'>
                    <img src={activity.url_icon} className='w-10' alt={activity.name_ar} />
                    <div className='text-slate-500 flex gap-1'>
                        <input type='checkbox' id={`check-${activity.id}`} onChange={()=>onchangeHanndler(activity.id)}  checked={ activitySelected.includes(activity.id) } className='' /> 
                        <label htmlFor={`check-${activity.id}`}>{activity.name_ar}</label>  
                    </div>
                </div>
                ))
            }
        </div>
    )
}

export default ActivitiesBar