import React,{useState} from 'react'
import { MwInputFile } from '../../ui'

const FormImages = ({
    selectedImage,
    loadingUploadPic,
    handleImageUpload,
    selectedImageId,
    loadingUploadId,
    handleImageUploadId,
    selectedImageCardWork,
    loadingUploadCardWork,
    handleImageUploadWorkCard

}) => {
    const [tabSelected,setTabSelected] = useState('MemberPic');
    return (
        <div className='mt-11 w-64 ps-3  flex flex-col items-center justify-between text-xs'>
            <div className='w-64 flex justify-between items-center gap-3  px-3 py-2 bg-slate-200 rounded-t-3xl cursor-default'>
                <div onClick={ () => setTabSelected('MemberPic') } className={`flex-1  border-l border-slate-300 text-center  hover:font-bold ${ tabSelected === 'MemberPic' && 'bg-slate-300 rounded-3xl  p-2'} `}>الصورة</div>
                <div onClick={ () => setTabSelected('MemberIdenitiy') }className={`flex-1  border-l border-slate-300   text-center hover:font-bold ${ tabSelected === 'MemberIdenitiy' && 'bg-slate-300 rounded-3xl  p-2'} `}>الهوية</div>
                <div onClick={ () => setTabSelected('MemberWorkCard') }className={`flex-1  text-center hover:font-bold ${ tabSelected === 'MemberWorkCard' && 'bg-slate-300 rounded-3xl  p-2'} `}>كارت العمل</div>
            </div>
            <div className='w-64'>
            { 
                tabSelected === "MemberPic" &&
                <div>
                    <img 
                        src={ !!selectedImage ? selectedImage :'/assets/images/new-employee.jpg'} 
                        title='Employee Image'
                        className='rounded-b-3xl'  
                        /> 
                    <MwInputFile 
                        loading={loadingUploadPic} 
                        onChangeHandler={handleImageUpload}
                        /> 
                </div> 
            }
            { 
                tabSelected === "MemberIdenitiy" &&
                <div>
                    <img 
                        src={ !!selectedImageId ? selectedImageId :'/assets/images/Download-digital-Iqama-online-through-Absher-Saudi-Expatriates.com_.jpg'} 
                        title='Employee Image ID'
                        className='rounded-b-3xl'  
                        /> 
                    <MwInputFile 
                        loading={loadingUploadId} 
                        onChangeHandler={handleImageUploadId}
                        /> 
                </div> 
            }
            { 
                tabSelected === "MemberWorkCard" &&
                <div>
                    <img 
                        src={ !!selectedImageCardWork ? selectedImageCardWork :'/assets/images/employee-card.jpg'} 
                        title='Employee Image ID'
                        className='rounded-b-3xl'  
                        /> 
                    <MwInputFile 
                        loading={loadingUploadCardWork} 
                        onChangeHandler={handleImageUploadWorkCard}
                        /> 
                </div> 
            }
            </div>
        </div>
    )
}

export default FormImages