import React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { MwSpinnerButton, NoDataFound } from '../ui'
import { useInvoice } from '../../hooks';
import parse from 'html-react-parser';

const InvListBasic = ({
    data,
    loading=true,
    links=[],
    paginateHandler = (url)=>{} ,
    withBorder=true,
    withSummary=false,
}) => {
    const params = useParams();
    const navigate = useNavigate();
    const {formatter} = useInvoice()
    return (
        <>
            {!loading ? 
            <div className={`flex-1 ${ withBorder && ' border rounded-xl ' }` }>
                { data?.length > 0 ? <ul>
                    <li className={`flex justify-between p-3 text-xs border-b bg-slate-100 `}>
                        { !withSummary && <div className='w-8 px-2 text-center'>#</div>}
                        <div className='w-24  text-center'>رقم الفاتورة</div>
                        <div className='flex-1'>اسم العميل</div>
                        <div className='w-24   text-center'>مبلغ الفاتورة</div>
                        { !withSummary && <div className='w-32 px-1  text-center'>تاريخ الفاتورة</div> }
                        { <div className='w-24 px-1  text-center'> الحالة </div>}
                    </li>
                    <div className={ ` ${ !withSummary && 'h-[calc(100vh-290px)]'} overflow-auto pb-3`}>
                        {data.map((result,index) => (
                            <div key={result.id}>
                                <Link to={`/${params.tenant}/invoices/show/${result.id}/${result.company_id}/${result.branch_id}`} key={result.id} >
                                    <li className={` flex justify-between p-2 text-xs  hover:bg-indigo-50 hover:font-bold ${ data?.length !== index+1 && ' border-b' } `}>
                                    { !withSummary &&  <div className='w-8 px-2  text-center'>{index+1} </div> }
                                        <div className='w-24   text-center'>{result.inv_no} </div>
                                        <div className='flex-1 flex justify-between items-center'>
                                            <span>
                                                {result.customer_name || 'عميل عام'}
                                            </span>
                                        </div>
                                        <div className='w-24  flex  items-center text-center'>{ formatter.format(result.net_amount) } </div>
                                        { !withSummary && <div className='w-32 px-1 flex  items-center text-center'>{result.inv_date.substring(0,16)}</div> }

                                        { <div className={`w-24 flex flex-col gap-1 items-center justify-center  `}>
                                            {(!result.is_returned && result.is_paid) && <span className='w-full text-center  px-2 pb-1 rounded-lg bg-green-200'>مدفوعة</span>  }
                                            {(!result.is_returned && !result.is_paid) && <span className='w-full bg-slate-200 text-center   px-2 pb-1 rounded-lg'>غير مدفوعة</span> }
                                            {result.is_returned && 
                                                <div className='flex  gap-2 px-3'>
                                                    <span className=' text-center px-2 pb-1 rounded-lg bg-orange-100'>مرتجع</span> 
                                                
                                                    <span onClick={()=>navigate(`/${params.tenant}/invoices-returns/show/${result.id}/${result.company_id}/${result.branch_id}`)} className=' text-center px-2 pb-1 rounded-lg bg-indigo-100'>عرض</span>     
                                                
                                                </div>
                                            }
                                        </div>}
                                    </li>
                                </Link>
                            </div>
                        ))}
                    </div>
                    { links.length >0 &&
                        <div id="dataLinks" className="list-none bg-slate-100 rounded-b-xl flex gap-3 border-t pt-3 px-5 pb-3 cursor-default">
                        {
                            links.length > 3 && 
                            links.map((item,i) => <li key={i} className='hover:font-bold' onClick={()=>item?.url && paginateHandler(item?.url.replace('http','https'))} >{ parse(item.label)}</li>)
                        }
                        </div> 
                    }
                </ul> : <NoDataFound msg='لا توجد فواتير ' />  }
            </div> : <div className='flex-1'>
                        <MwSpinnerButton withLabel={false} isFullCenter={true} />
                    </div> }
        </>
    )
}

export default InvListBasic