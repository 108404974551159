import React,{useEffect} from 'react'
import { MwButton, MwCard, MwInputText, MwSelector, MwSpinnerButton } from '../ui'
import { useCurrent, useFetch } from '../../hooks'
import { useState } from 'react';

const SubscriptionMembershipForm = ({
    mode,
    setMode,
    onSubmitHandler,
    errors,
    selectedSubscription,
    setSelectedSubscription,
    startDate,
    setStartDate,
    endDate,
    setEndDate
}) => {

    const {currentTenantId,currentCompanyId,currentBranchId} = useCurrent();
    const {data:resultSubscription,loading:loadingSubscription} = useFetch(`${process.env.REACT_APP_API_BASE_URL}/subscriptions/${currentTenantId}/${currentCompanyId}/${currentBranchId}`)
    const [subscriptionsList, setSubscriptionsList] = useState([])
 

    const _init = () => {

    }

    const calcEndDate = (months=0,days=0) => {
        const _startDate = new Date(startDate)
        var newDate = new Date(_startDate.setMonth(_startDate.getMonth()+ months));
        newDate = new Date(_startDate.setDate(_startDate.getDate()+ days));
        return newDate.toISOString().split('T')[0];
    }

    useEffect(() =>{
        _init();
    },[]);

    useEffect(() =>{
        if(resultSubscription){
            console.log('resultSubscription =>',resultSubscription)
            const _subscriptionsList = resultSubscription?.data?.data?.map(subscription =>({
                label: subscription.name_ar,
                value: subscription.id,
                data:subscription
            }))
            setSubscriptionsList(_subscriptionsList)

        }
    },[resultSubscription]);

    useEffect(() =>{
        if(selectedSubscription){
            console.log('selectedSubscription => ',selectedSubscription)
            setEndDate(calcEndDate(selectedSubscription.data.period_month || 0,selectedSubscription.data.period_days || 0))
        }
    },[selectedSubscription])
    
    return (
        <MwCard>
            <div className='w-full flex flex-col gap-2'>
                { loadingSubscription ? <MwSpinnerButton withLabel={false} /> :
                <div className='flex-1 flex gap-10'>
                    <div className='flex-1'>
                        <MwSelector
                            label={'اختار الاشتراك'}
                            initalValue='اختار الاشتراك...'
                            dataType={'labelValue'}
                            _data = {subscriptionsList}
                            selectedItem={selectedSubscription}
                            setSelectedItem={setSelectedSubscription}
                            withAddNew={false}
                            invalid={!!errors?.selectedSubscription}
                            invalidMsg={errors?.selectedSubscription}
                            />
                    </div>
                    { selectedSubscription && 
                        <div className='flex gap-5 text-slate-500 text-xs '>
                            <div className='flex flex-col  justify-center items-center border-l pl-5 '>
                                <span className='text-xs'>السعر</span>
                                <span className='font-bold'>{selectedSubscription.data.price}</span>
                            </div>
                            <div className='flex flex-col   justify-center items-center  border-l pl-5 '>
                                <span className='text-xs'>المدة</span>
                                <div>
                                    <span className='font-bold'>{selectedSubscription.data.period_month}</span> 
                                    <span> أشهر </span>
                                    <span> - </span>
                                    <span className='font-bold'>{selectedSubscription.data.period_days}</span>
                                    <span> يوم </span>
                                </div>
                            </div>
                            <div className='flex flex-col justify-center items-center  border-l pl-5 '>
                                <span className='text-xs'>ايام الايقاف المسموح بها</span>
                                <span className='font-bold'>{selectedSubscription.data.suspend_days || '0'}</span>
                            </div>
                            <div className='flex flex-col  justify-center items-center  border-l pl-5 '>
                                <span className='text-xs'>مرات الايقاف المسموح بها</span>
                                <span className='font-bold'>{selectedSubscription.data.suspend_times || '0'}</span>
                            </div>
                            <div className='flex flex-col  justify-center items-center pl-5 '>
                                <span className='text-xs'> الايام المسموح بها</span>
                                <span className='font-bold'>{ JSON.parse(selectedSubscription.data.available_days).length === 0 ? 'جميع الايام' : JSON.parse(selectedSubscription.data.available_days).map(day => day).join(',') }</span>
                            </div>
                        </div>
                    }
                </div>
                }
                <hr className='mt-4 mb-2' />
                <div className='flex gap-5'>
                    <div className='flex-1'>
                        <MwInputText
                            inputType="date"
                            label="تاريخ البداية"
                            value={startDate}
                            onChange={(e)=>setStartDate(e.target.value)}
                            />
                    </div>
                    <div className='flex-1'>
                        <MwInputText
                            inputType="date"
                            label="تاريخ النهاية"
                            value={endDate}
                            disabled={true}
                            onChange={(e)=>setEndDate(e.target.value)}
                            />
                    </div>
                </div>
                <div className='flex justify-end pt-7 pb-2'>
                    <MwButton onClick={onSubmitHandler} type='saveBtn'>اعتمد الاشتراك</MwButton>
                </div>
            </div>
        </MwCard>
    )

}

export default SubscriptionMembershipForm