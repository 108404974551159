import React from 'react'
import {  MwInputText,MwButton } from '../ui'
import { CiSearch } from "react-icons/ci";

const MembershipSearchItem = ({cbSearch,term,setTerm}) => {
    return (
        <div>
            <div className='flex  items-center gap-1 text-slate-500 text-sm my-2'>
                <CiSearch size={18}/>
                <span className=''>البحث</span>
            </div>
            <div>
                <MwInputText
                    value={term}
                    placeholder = 'ادخل رقم العضوية او رقم الجوال'
                    onChange={(e)=>setTerm(e.target.value)}
                    />
            </div>
            <div className='w-full pt-2'>
                <MwButton type='secondary' onClick={cbSearch} classNameCustom='w-full' ><CiSearch size={18}/> عرض النتائج</MwButton>
            </div>

        </div>
    )
}

export default MembershipSearchItem