import React from 'react'
import { MwInputText, MwSelector, MwSelectorMulti } from '../../ui'
import {MONTH_LIST} from '../../../constants/subscription'
const TabPrice = ({
    errors,
    formState,
    setFormState,
    periodMonthSelected,
    setPeriodMonthSelected
}) => {
  

    return (
        <div  id='tab-price' className='flex flex-col gap-3'>
            <div className='flex gap-1'>
                <div className='flex-1'>
                    <MwInputText
                        inputType = 'number'
                        label ='التكلفة'
                        value={formState.cost}
                        onChange={(e) => setFormState((state)=> ({...state,cost:e.target.value}) )}
                        invalid = {!!errors?.cost}
                        invalidMsg = {errors?.cost}
                        />
                </div>
                <div className='flex-1'>
                    <MwInputText
                        inputType = 'number'
                        label ='السعر'
                        value={formState.price}
                        onChange={(e) => setFormState((state)=> ({...state,price:e.target.value}) )}
                        invalid = {!!errors?.price}
                        invalidMsg = {errors?.price}
                        />
                </div>
                <div className='flex-1'>
                    <MwInputText
                        inputType = 'number'
                        label ='عدد التذاكر'
                        value={formState.ticketsCount}
                        onChange={(e) => setFormState((state)=> ({...state,ticketsCount:e.target.value}) )}
                        invalid = {!!errors?.ticketsCount}
                        invalidMsg = {errors?.ticketsCount}
                        />
                </div>
            </div>
            <div className='flex gap-1'>
                <div className='flex-1 flex gap-1'>
                    <div className='flex-1'>
                        <MwSelector
                            label={'المدة / شهر'}
                            initalValue='اختار...'
                            dataType={'labelValue'}
                            _data={MONTH_LIST}
                            selectedItem={periodMonthSelected}
                            setSelectedItem={setPeriodMonthSelected}
                            withAddNew={false}
                            withSearch={false}
                            />
                    </div>
                    <div className='flex-1'>
                        <MwInputText
                            inputType = 'number'
                            label =' المدة (ايام)'
                            value={formState.periodDays}
                            onChange={(e) => setFormState((state)=> ({...state,periodDays:e.target.value}) )}
                            invalid = {!!errors?.periodDays}
                            invalidMsg = {errors?.periodDays}
                            />
                    </div>
                </div>
                <div className='flex-1'>
                    <MwInputText
                        inputType = 'time'
                        label =' بداية الفترة (ساعات : دقيقة)'
                        value={formState.startPeriodByHour}
                        onChange={(e) => setFormState((state)=> ({...state,startPeriodByHour:e.target.value}) )}
                        invalid = {!!errors?.startPeriodByHour}
                        invalidMsg = {errors?.startPeriodByHour}
                        />
                </div>
                <div className='flex-1'>
                    <MwInputText
                        inputType = 'time'
                        label ='نهاية الفترة (ساعات : دقيقة)'
                        value={formState.endPeriodByHour}
                        onChange={(e) => setFormState((state)=> ({...state,endPeriodByHour:e.target.value}) )}
                        invalid = {!!errors?.endPeriodByHour}
                        invalidMsg = {errors?.endPeriodByHour}
                        />
                </div>
            </div>

        </div>
    )
}

export default TabPrice