import React,{useEffect,useState} from 'react'
import { useParams } from 'react-router-dom';

import { CardHeaderWithActions } from '../../components/ui'
import { MembershipsList } from '../../components/memberships/MembershipsList'
import { useCurrent,usePost,useFetch } from '../../hooks';
import MembershipSearchItem from '../../components/memberships/MembershipSearchItem';
import MembershipsFilter from '../../components/memberships/MembershipsFilter';
import { MembershipInfo } from '../../components/memberships/MembershipInfo';
import MembershipsSubscriptions from '../../components/memberships-subscriptions/MembershipsSubscriptions';

const MembershipsSubscriptionsHome = () => {
    const params = useParams();
    const [mode,setMode] = useState('Home');
    const [selectedItem,setSelectedIitem] = useState();
    const {currentTenantId,currentCompanyId,currentBranchId,currentLangId} = useCurrent();
    const {data:membershipsResult,loading:membershipsLoading,postData:getMemberships} = usePost();
    
    /// get Membership Groups 
    const {data:membershipGroupsResult,loading:membershipGroupsLoading} = useFetch(`${process.env.REACT_APP_API_BASE_URL}/memberships-groups/${currentTenantId}/${currentCompanyId}/${currentBranchId}`)
    /// get Membership Status 
    const {data:membershipStatusResult,loading:membershipStatusLoading} = useFetch(`${process.env.REACT_APP_API_BASE_URL}/memberships-status`)
    /// get Nationlaltiy  
    const {data:nationalityResult,loading:nationalityLoading} = useFetch(`${process.env.REACT_APP_API_BASE_URL}/countries`)
    const [filterState,setFilterState] = useState({
        startDate: '',
        endDate:''
    })
    const [tagsList,setTagsList] = useState([])
    const [nationalitySelected,setNationalitySelected] = useState(null);
    const [statusSelected,setStatusSelected] = useState({
        label:currentLangId === 'en' ? 'New' : 'جديد',
        value: 1,
        lang:currentLangId
    });
    const [groupSelected,setGroupSelected] = useState({
        label:currentLangId === 'en' ? 'Member' : 'أعضاء النادي',
        value: 1,
        lang:currentLangId
    });
    const [term,setTerm] = useState('');
    const filterParams ={
        limit: 25,
        tenant_id: currentTenantId,
        company_id: currentCompanyId,
        branch_id: currentBranchId,
    };
    


    const getMembershipsHandler = (_filterParams=filterParams,url = `${process.env.REACT_APP_API_BASE_URL}/memberships/filterBy`  ) =>{
        getMemberships(url,_filterParams);
    }

    const filterHandler = () => {
        const _dataFilter = {
            limit: 25,
            tenant_id: currentTenantId,
            company_id: currentCompanyId,
            branch_id: currentBranchId,
            start_date: filterState?.startDate || null,
            end_date: filterState?.endDate || null,
            status_id: statusSelected?.value  || null,
            group_id: groupSelected?.value || null,
            nationaltiy_id: nationalitySelected?.value || null,
            term: term || null,
        }
        getMembershipsHandler(_dataFilter);
    }
    const searchHandler = () => {
        const _dataFilter = {
            limit: 25,
            tenant_id: currentTenantId,
            company_id: currentCompanyId,
            branch_id: currentBranchId,
            term: term || null,
        }
        getMembershipsHandler(_dataFilter);
    }
   
    useEffect(() => {
        getMembershipsHandler();
    },[]);
    
    useEffect(()=>{
        if(membershipsResult){
            console.log(membershipsResult);
        }
    },[membershipsResult]);
    return (
        <div className='p-5'>
            <CardHeaderWithActions title={'اشتراكات الاعضاء'}>
                <div className='flex'>
                    <div className=' border-l border-slate-400 p-2 rounded-l-3xl text-sm font-bold'>اختيار العضوية</div>
                    <div className=' border-l border-slate-400 p-2 rounded-l-3xl text-sm'>اشتراكات العضو</div>
                </div>
            </CardHeaderWithActions>
            {  mode === 'Home' && 
                <div className='flex gap-3   '>
                    <div className='flex-1'>
                        <MembershipsList 
                            params={params} 
                            currentLangId={currentLangId} 
                            data={membershipsResult} 
                            loading={membershipsLoading} 
                            getMembershipsHandler={getMembershipsHandler}
                            setMode = {setMode}
                            setSelectedIitem={setSelectedIitem}
                            isShowDate = {false}
                            isShowStatus = {false}
                            target = 'subscriptions'
                            />
                    </div>
                    <div className='w-64'>
                        <MembershipSearchItem term ={term} setTerm={setTerm}  cbSearch={searchHandler}/>
                        
                        <MembershipsFilter
                            currentLangId = {currentLangId}
                            filterState = {filterState}
                            setFilterState ={setFilterState}
                            tagsList = {tagsList}
                            setTagsList = {setTagsList}
                            membershipGroupsResult = {membershipGroupsResult}
                            membershipGroupsLoading ={membershipGroupsLoading}
                            membershipStatusResult = {membershipStatusResult}
                            membershipStatusLoading = {membershipStatusLoading}
                            nationalityResult = {nationalityResult}
                            nationalityLoading = {nationalityLoading}
                            nationalitySelected = {nationalitySelected}
                            setNationalitySelected = {setNationalitySelected}
                            statusSelected = {statusSelected}
                            setStatusSelected = {setStatusSelected}
                            groupSelected = {groupSelected}
                            setGroupSelected = {setGroupSelected}
                            filterHandler = {filterHandler}
                            />
                    </div>
                    
                </div>
            }
            {
                mode === 'subscriptions' && 
                <div className='flex flex-col gap-3  '>
                    <MembershipInfo item={selectedItem} />
                    <MembershipsSubscriptions membershipNo={selectedItem.membership_no} membershipId={selectedItem.id} />
                </div>
            }
        </div>
    )
}

export default MembershipsSubscriptionsHome