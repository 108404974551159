import React from 'react'

const MembershipStatusItem = ({statusId,statusName}) => {
    let custClass = '';
    if(statusId === 1) {
        custClass = 'bg-slate-200';
    }else if(statusId === 2) {
        custClass = 'bg-slate-300';
    }else if(statusId === 3) {
        custClass = 'bg-slate-400';
    }else if(statusId === 11) {
        custClass = 'bg-slate-500 text-slate-50';
    }else if(statusId === 12) {
        custClass = 'bg-slate-600 text-slate-50';
    }
    return (
        <div className={` p-1 rounded-xl ${custClass} `}>{statusName}</div>
    )
}

export default MembershipStatusItem