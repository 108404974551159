import React from 'react'

const MwSpinnerButton = ({withLabel=true,isFullCenter = false,label='جاري التحميل...'}) => {
    return (
        <button type="button" className={`flex items-center gap-1 justify-center text-xs  primary-color  p-2 ${ isFullCenter && 'w-full '}`}   disabled >
            <svg className=" border-2 rounded-full   border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]  animate-spin h-5 w-5 mr-1 " viewBox="0 0 24 24">
                
            </svg>
            { withLabel && <span className='px-1'>{label}</span> }
        </button>
    )
}

export default MwSpinnerButton