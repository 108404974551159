import {useState,useEffect} from 'react'
import { CardHeaderWithActions, MwButton, MwSpinnerButton } from '../../components/ui'
import { useCurrent, useFetch, usePost } from '../../hooks';
import FormHeaderMembership from '../../components/memberships/form/FormHeaderMembership';
import FormPrivateInfo from '../../components/memberships/form/FormPrivateInfo';
import {idTypesList,genderList} from '../../constants/membership.js'
import FormImages from '../../components/memberships/form/FormImages.jsx';
import useUploadFile from '../../hooks/useUploadFile.js';
import {AlertMsg} from '../../components/ui'

const AddNewMembership = () => {
    
    const {currentTenantId,currentCompanyId,currentBranchId,currentBranch,currentLangId,userId} = useCurrent();
    const [errors,setErrors] = useState({});
    const allowImgTypes = ["image/png", "image/jpg", "image/jpeg", "image/gif"];
    // form state
    // const [selectedImage, setSelectedImage] = useState(null);
    // const [selectedImageId, setSelectedImageId] = useState(null);
    // const [selectedImageCardWork, setSelectedImageCardWork] = useState(null);
    const [formState,setFormState] = useState({
        membershipNo:'',
        nameAr: '',
        nameEn: '',
        idNo:'',
        idIssueNo: '',
        idIssueDate:'',
        idIssueDateH:'',
        idExpireDate:'',
        idExpireDateH:'',
        dob: '',
        dobH: '',
        mobile: '',
        email: '',
        address: '',
        notes: '',
    });
    const [membershipTagList,setMembershipTagList] = useState([]);
    const [idTypeSelected,setIdTypeSelected] = useState(null);
    const [genderSelected,setGenderSelected] = useState(null);
    const [nationalitySelected,setNationalitySelected] = useState(null);
    const [statusSelected,setStatusSelected] = useState({
        label:currentLangId === 'en' ? 'New' : 'جديد',
        value: 1,
        lang:currentLangId
    });
    const [groupSelected,setGroupSelected] = useState({
        label:currentLangId === 'en' ? 'Member' : 'أعضاء النادي',
        value: 1,
        lang:currentLangId
    });
    console.log("currentBranch" , currentBranch || "0")
    /// get Membership no 
    const {data:genMembershipNoResult,loading:genMembershipNoLoading,refreshHandler:genMemberShipNo} = useFetch(`${process.env.REACT_APP_API_BASE_URL}/memberships/gen-membership-no/${currentTenantId}/${currentCompanyId}/${currentBranchId}/${currentBranch?.branchCode || "00" }`)
    /// get Membership Groups 
    const {data:membershipGroupsResult,loading:membershipGroupsLoading} = useFetch(`${process.env.REACT_APP_API_BASE_URL}/memberships-groups/${currentTenantId}/${currentCompanyId}/${currentBranchId}`)
    /// get Membership Status 
    const {data:membershipStatusResult,loading:membershipStatusLoading} = useFetch(`${process.env.REACT_APP_API_BASE_URL}/memberships-status`)
    /// get Nationlaltiy  
    const {data:nationalityResult,loading:nationalityLoading} = useFetch(`${process.env.REACT_APP_API_BASE_URL}/countries`)
    // upload the membership image
    const {location:selectedImage,loading:loadingUploadPic,handleUpload:handleImageUpload} = useUploadFile(allowImgTypes,'activedeer-memberships-pic','image')
    // upload the membership identifiy
    const {location:selectedImageId,loading:loadingUploadId,handleUpload:handleImageUploadId} = useUploadFile(allowImgTypes,'activedeer-memberships-ident','image')
    // upload the membership identifiy
    const {location:selectedImageCardWork,loading:loadingUploadCardWork,handleUpload:handleImageUploadWorkCard} = useUploadFile(allowImgTypes,'activedeer-memberships-card-work','image')

    const {data:postResult,loading:postLoading,postData} = usePost();
    const {data:postCustomerResult,loading:postCustomerLoading,postData:postCustomerData} = usePost();
    const resetForm = () => {
        setFormState({
            membershipNo:'',
            nameAr: '',
            nameEn: '',
            idNo:'',
            idIssueNo: '',
            idIssueDate:'',
            idIssueDateH:'',
            idExpireDate:'',
            idExpireDateH:'',
            dob: '',
            dobH: '',
            mobile: '',
            email: '',
            address: '',
            notes: '',
        });
    }
    const validateForm = () => {
        setErrors({});
        let _errors = {};
        if(!formState?.nameAr){
            _errors  = {..._errors,nameAr:'يجب ادخال اسم العضو'};
            setErrors({..._errors})
        }

        if(!formState?.mobile){
            _errors  = {..._errors,mobile:'يجب ادخال رقم الجوال'};
            setErrors({..._errors})
        }

        if(idTypeSelected?.value === 'Iqama'){
            if(formState.idNo.length !== 10){
                _errors  = {..._errors,idNo:'يجب ان يكون رقم الهوية ١٠ ارقام'};
                setErrors({..._errors})
            }
        }
        if(_errors && Object.keys(_errors).length > 0 ){
            console.error(_errors);
            return true;
        }
        return false;
    }
    const onSubmit = () => {
        if(validateForm()){
            return;
        }
        const _data = {
            membership_no : formState?.membershipNo || null,
            name_ar : formState?.nameAr || null,
            name_en : formState?.nameEn  || null,
            email : formState?.email  || null,
            mobile : formState?.mobile  || null,
            notes : formState?.notes  || null,
            dob : formState?.dob  || null,
            dob_h : formState?.dobH  || null,
            gender : genderSelected?.value  || null,
            id_no : formState?.idNo  || null,
            id_type : idTypeSelected?.value  || null,
            id_issue_no : formState?.idIssueNo || null,
            id_expire_date :formState?.idExpireDate || null,
            id_expire_date_h : formState?.idExpireDateH || null,
            id_issue_date :formState?.idIssueDate || null,
            id_issue_date_h : formState?.idIssueDateH  || null,
            parent_id : 0,
            status_id : statusSelected?.value  || null,
            nationaltiy_id : nationalitySelected?.value  || null,
            category_id : 0,
            group_id : groupSelected?.value  || null,
            tags : JSON.stringify(membershipTagList)  || null,
            pic_url : selectedImage  || null,
            id_img_url : selectedImageId  || null,
            work_card_img_url : selectedImageCardWork  || null,
            branch_id : currentBranchId,
            company_id : currentCompanyId,
            tenant_id : currentTenantId,
            created_by : userId
        }
        
        postData(`${process.env.REACT_APP_API_BASE_URL}/memberships`,_data)
        
    }

    useEffect(() => {
        if(postResult?.status === 1){  
            const _data={
                fullname: [{
                    text : formState?.nameAr ,
                    lang: "ar"
                },{
                    text : formState?.nameEn ,
                    lang: "en" 
                }] ,
                mobileNo: formState?.mobile  || null ,
                // email: formState?.email || null ,
                customerClassName: "Membership",
                membershipId: formState?.membershipNo.trim(),
                isActive:true,
                tenantId:currentTenantId,
                companyId:currentCompanyId,
                branchId:currentBranchId
            }
    
            postCustomerData(`${process.env.REACT_APP_Auth_API_BASE_URL}/customers`,_data)
    
            resetForm()
            genMemberShipNo()
        }
    },[postResult]);

    useEffect(() => {
        if(genMembershipNoResult){
            setFormState(state => ({...state , membershipNo: genMembershipNoResult?.data  }))
        }
    },[genMembershipNoResult]);

    return (
        <div className='h-full flex flex-col p-5'>
            <CardHeaderWithActions title={`أضف عضوية جديدة`}>
                {postLoading ? <MwSpinnerButton/> : <MwButton onClick={onSubmit} type='saveBtn'>حفظ</MwButton>}
            </CardHeaderWithActions>
            <div className='flex flex-col'>
                {postResult?.status === 1 &&<AlertMsg>تم إضافة العضو بنجاح</AlertMsg>}
                <FormHeaderMembership
                    formState = {formState} 
                    setFormState = {setFormState}
                    errors = {errors}
                    membershipTagList ={membershipTagList}
                    setMembershipTagList = {setMembershipTagList}
                    membershipGroupsList = {membershipGroupsResult?.data}
                    membershipGroupsListLoading = {membershipGroupsLoading}
                    currentLangId = {currentLangId}
                    groupSelected = {groupSelected}
                    setGroupSelected = {setGroupSelected}
                    nationalitySelected = {nationalitySelected}
                    statusSelected ={statusSelected}
                    setStatusSelected = {setStatusSelected}
                    membershipStatusResult ={membershipStatusResult?.data}
                    membershipStatusLoading = {membershipStatusLoading}
                    />
                <div id='tabs' className='flex gap-3 my-3 items-start '>
                    <div className='flex-1'>
                        <div className='flex text-sm text-slate-600 ' >
                            <div className='px-3 py-2 me-1  rounded-t-xl bg-slate-100 font-bold'>البيانات الشخصية</div>
                            <div className='px-3 py-2 mx-1 bg-slate-200 rounded-t-xl'>المعلومات الاضافية</div>
                            <div className='px-3 py-2 mx-1 bg-slate-200 rounded-t-xl'> الروابط </div>
                            <div className='px-3 py-2 mx-1 bg-slate-200 rounded-t-xl'>الاشتراكات </div>
                            <div className='px-3 py-2 mx-1 bg-slate-200 rounded-t-xl'>السجل</div>
                            <div className='px-3 py-2 mx-1 bg-slate-200 rounded-t-xl'> الإعدادات </div>
                        </div>
                        <div id='allTabs' className=' pb-5 border border-slate-100 bg-slate-100 rounded-b-xl rounded-tl-xl '>
                            <FormPrivateInfo
                                currentLangId = {currentLangId}
                                errors={errors}
                                formState = {formState}
                                setFormState = {setFormState}
                                idTypesList = {idTypesList}
                                idTypeSelected = {idTypeSelected}
                                setIdTypeSelected = {setIdTypeSelected}
                                genderList ={genderList}
                                genderSelected = {genderSelected}
                                setGenderSelected ={setGenderSelected}
                                nationalitySelected = {nationalitySelected}
                                setNationalitySelected = {setNationalitySelected}
                                nationalityList = {nationalityResult?.data}
                                nationalityListLoading = {nationalityLoading}
                                />
                        </div>
                    </div>
                    <FormImages
                        selectedImage = {selectedImage}
                        loadingUploadPic = {loadingUploadPic}
                        handleImageUpload = {handleImageUpload}
                        selectedImageId = {selectedImageId}
                        loadingUploadId = {loadingUploadId}
                        handleImageUploadId = {handleImageUploadId}
                        selectedImageCardWork = {selectedImageCardWork}
                        loadingUploadCardWork = {loadingUploadCardWork}
                        handleImageUploadWorkCard = {handleImageUploadWorkCard}
                        />
                </div>
            </div>
        </div>
    )
}

export default AddNewMembership