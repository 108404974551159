import React from 'react' 
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// React Icons
import {MdOutlineAddBox} from 'react-icons/md'
import {TbFileInvoice} from 'react-icons/tb'
import {FiSettings} from 'react-icons/fi'
import {AiOutlineDashboard} from 'react-icons/ai'
import {LuLayoutDashboard} from 'react-icons/lu'
import {HiOutlineDocumentReport} from 'react-icons/hi'
import {PiUsersThreeThin} from 'react-icons/pi'
import {LiaUsersCogSolid,LiaUsersSolid} from 'react-icons/lia'
import {RiQuoteText} from 'react-icons/ri'
import { FaFileInvoice } from "react-icons/fa";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";

// Custom hooks
import { useTenant } from '../hooks'

const MenuSide = ({openMenu,setOpenMenu}) => {
    const [t] = useTranslation('global')
    const {tenantUsername} = useTenant();
    return (
        <div 
            // onMouseOver={()=>setOpenMenu(true)}
            className={`  z-50   flex items-center   transition-all duration-500  ease-out  h-screen      primary-bg-color border-b    ${openMenu ? 'fixed px-3 w-9 flex md:w-40 md:relative ' : 'fixed w-0 hidden md:flex md:w-12 md:px-3  '}`}>
                <ul className=' cursor-default  flex  flex-col gap-5 items-center  text-white  text-xs  '>
                    <li className={`w-full  menu-border-color   border-b pb-2 hover:text-yellow-400 ${ openMenu || 'justify-center' } `}>
                        <NavLink to={`/${tenantUsername}`} className={`flex items-center gap-2 `} end >
                            <AiOutlineDashboard size={25}  title={t('menu.Dashboard')} />
                            { openMenu && <span className='whitespace-nowrap hidden md:flex'>{t('menu.Dashboard')}</span>}
                        </NavLink>
                    </li>
                    <li className={`w-full menu-border-color border-b pb-2 hover:text-yellow-400 ${ openMenu || 'justify-center' } `}>
                        <NavLink to={`/${tenantUsername}/memberships/add`} className={`flex items-center gap-2 `} end >
                            <MdOutlineAddBox size={25} title="أضف عضوية جديدة" />
                            { openMenu && <span className='whitespace-nowrap hidden md:flex'>أضف عضوية جديدة</span>}
                        </NavLink>
                    </li>
                    <li className={` w-full gap-2 flex items-center  menu-border-color  border-b pb-2 hover:text-yellow-400 ${ openMenu || 'justify-center' } `}>
                        <NavLink to={`/${tenantUsername}/memberships`} className={`flex items-center gap-2 `} end >
                            <TbFileInvoice size={25} title={`إدارة العضويات`}/>
                            { openMenu && <span className='whitespace-nowrap hidden md:flex'>{`إدارة العضويات`}</span>}
                        </NavLink>
                    </li>
                    <li 
                        className={` w-full gap-2 flex items-center  menu-border-color  border-b pb-2 hover:text-yellow-400 ${ openMenu || 'justify-center' } `}>
                        <NavLink to={`/${tenantUsername}/subscriptions`} className={`flex items-center gap-2 `} end >
                            <LuLayoutDashboard size={25} title={`ادارة الاشتركات`}/>
                            { openMenu && <span className='whitespace-nowrap hidden md:flex'> ادارة الاشتراكات</span>}
                        </NavLink>
                    </li>
                    <li className={`w-full menu-border-color  border-b pb-2 hover:text-yellow-400 ${ openMenu || 'justify-center' } `}>
                        <NavLink to={`/${tenantUsername}/memberships-subscriptions`} className={`flex items-center gap-2 `} end >
                            <RiQuoteText size={25} title=' اشتراكات الاعضاء '  />
                            { openMenu && <span className='whitespace-nowrap hidden md:flex'>اشتراكات الاعضاء </span>}
                        </NavLink>
                    </li>
                    <li className={`w-full menu-border-color  border-b pb-2 hover:text-yellow-400 ${ openMenu || 'justify-center' } `}>
                        <NavLink to={`/${tenantUsername}/invoices`} className={`flex items-center gap-2 `} end >
                            <FaFileInvoice size={25} title='الفواتير'  />
                            { openMenu && <span className='whitespace-nowrap hidden md:flex'> الفواتير </span>}
                        </NavLink>
                    </li>
                    <li className={`w-full menu-border-color  border-b pb-2 hover:text-yellow-400 ${ openMenu || 'justify-center' } `}>
                        <NavLink to={`/${tenantUsername}/transactions`} className={`flex items-center gap-2 `} end >
                            <LiaFileInvoiceDollarSolid size={25} title='سندات الدفع'  />
                            { openMenu && <span className='whitespace-nowrap hidden md:flex'> سندات الدفع </span>}
                        </NavLink>
                    </li>
                    <li className={` w-full gap-2 flex items-center  border-indigo-100/50  pb-1 hover:text-indigo-600 ${ openMenu || 'justify-center' } `}>
                        <NavLink to={`/${tenantUsername}/invoices-zatca`} className={`flex items-center gap-2 `} end >
                            <img src='/assets/icons/zatca-logo.png' className='max-w-6 opacity-75  hover:opacity-100' alt={'zatca'} />
                            { openMenu && <span className='whitespace-nowrap hidden md:flex'>{'Zatca'}</span>}
                        </NavLink>
                    </li>
                   
                    {/* <li 
                        className={` w-full gap-2 flex items-center  border-indigo-100/50  border-b pb-1 hover:text-indigo-600 ${ openMenu || 'justify-center' } `}>
                        <CiDiscount1 size={20} title={t('menu.Promotions')}/>
                        { openMenu && <span className='whitespace-nowrap hidden md:flex'>{t('menu.Promotions')}</span>}
                    </li> */}
                    <li 
                        className={` w-full gap-2 flex items-center  menu-border-color  border-b pb-2 hover:text-yellow-400 ${ openMenu || 'justify-center' } `}>
                        <NavLink to={`/${tenantUsername}/reports`} className={`flex items-center gap-2 `} end >
                            <HiOutlineDocumentReport size={25} title={'التقارير'} />
                            { openMenu && <span className='whitespace-nowrap hidden md:flex'>{'التقارير'}</span>}
                        </NavLink>
                    </li>
                    <li 
                        className={` w-full gap-2 flex items-center  menu-border-color  border-b pb-2 hover:text-yellow-400 ${ openMenu || 'justify-center' } `}>
                        <NavLink to={`/${tenantUsername}/settings`} className={`flex items-center gap-2 `} end >
                            <FiSettings size={25} title={t('menu.Settings')}/>
                            { openMenu && <span className='whitespace-nowrap hidden md:flex'> إعدادات النظام </span>}
                        </NavLink>
                    </li>
                    <li 
                        className={` w-full gap-2 flex items-center  menu-border-color  border-b pb-2 hover:text-yellow-400 ${ openMenu || 'justify-center' } `}>
                        <PiUsersThreeThin size={25} title={t('menu.Users')}/>
                        { openMenu && <span className='whitespace-nowrap hidden md:flex'>{t('menu.Users')}</span>}
                    </li>
                </ul>
        </div>
    )
}

export default MenuSide