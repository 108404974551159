import React from 'react'
import { MwSelector, MwSpinnerButton,MwSelectorMulti } from '../../ui'
import { useFetch } from '../../../hooks'

const TabLinks = ({
    serviceTypeSelected,
    setServiceTypeSelected,
    serviceClassSelected,
    setServiceClassSelected,
    currentLangId,
    errors,
    formState,
    setFormState,
    currentBranches,
    branchesSelling,
    setBranchesSelling,
    branchesProviding,
    setBranchesProviding,
    servicesTypesResult,
    servicesTypesListLoading,
    servicesClassesResult,
    servicesClassesListLoading
}) => {

    const _branchesList = currentBranches.map(branch =>({
        label : branch.name[0].text || branch.name[1].text,
        value : branch._id,
        lang: "ar"
    }))
    console.log('currentBranches => ',currentBranches)

    return (
        <div id='tab-link'>
            <div className='flex gap-3'>
                <div className='flex-1'>
                { servicesTypesListLoading ? <MwSpinnerButton label='جاري جلب انواع الاشتراكات' /> : <MwSelector
                        label={`نوع الاشتراك`}
                        initalValue='أختار نوع الاشتراك...'
                        _data={servicesTypesResult?.data?.map(group =>({
                            label: group[`name_${currentLangId}`] ,
                            value: group?.id,
                            lang:currentLangId
                        }))}
                        dataType={'labelValue'}
                        selectedItem={serviceTypeSelected}
                        setSelectedItem={setServiceTypeSelected}
                        withAddNew={false}
                        invalid={!!errors?.serviceTypeSelected}
                        invalidMsg={errors?.serviceTypeSelected}
                        />
                    }
                </div>
                <div className='flex-1'>
                { servicesClassesListLoading ? <MwSpinnerButton label='جاري جلب تصنيف الاشتراكات...' /> : <MwSelector
                        label={`تصنيف الاشتراك`}
                        initalValue='أختار تصنيف الاشتراك...'
                        _data={servicesClassesResult?.data?.map(group =>({
                            label: group[`name_${currentLangId}`] ,
                            value: group?.id,
                            lang:currentLangId
                        }))}
                        dataType={'labelValue'}
                        selectedItem={serviceClassSelected}
                        setSelectedItem={setServiceClassSelected}
                        withAddNew={false}
                        invalid={!!errors?.serviceClassSelected}
                        invalidMsg={errors?.serviceClassSelected}
                        />
                    }
                </div>
            </div>
            <div className='flex flex-col gap-3 pt-2'>
                <div className='flex-1'>
                    <MwSelectorMulti
                        label={`الفروع البائعة للاشتراك`}
                        initalValue='أختار الفروع البائعة للاشتراك...'
                        _data={_branchesList}
                        dataType={'labelValue'}
                        selectedItem={branchesSelling}
                        setSelectedItem={setBranchesSelling}
                        />
                </div>
                <div className='flex-1'>
                    <MwSelectorMulti
                        label={`الفروع المقدمة للاشتراك`}
                        initalValue='أختار الفروع المقدمة للاشتراك...'
                        _data={_branchesList}
                        dataType={'labelValue'}
                        selectedItem={branchesProviding}
                        setSelectedItem={setBranchesProviding}
                        />
                </div>
            </div>
        </div>        
    )
}

export default TabLinks