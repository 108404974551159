import React from 'react'

const MeunSideSetting = ({activeMenu,setActiveMenu}) => {

    const handleClick = (newValue) => {
        setActiveMenu(newValue)
    }

    return (
        <div className='w-48 rounded text-xs '>
            <ul className=''>
                

                <li 
                    onClick={() => handleClick('SMSSetting')} 
                    className={`cursor-default p-2 border-b hover:bg-slate-100 hover:font-medium ${activeMenu === 'SMSSetting' && 'bg-slate-100 font-medium'}`}>
                        اعدادات التنبيهات
                </li>
                <li 
                    onClick={() => handleClick('ReportSetting')} 
                    className={`cursor-default p-2 border-b hover:bg-slate-100 hover:font-medium ${activeMenu === 'ReportSetting' && 'bg-slate-100 font-medium'}`}>
                        اعدادات التقارير
                </li>
                <li 
                    onClick={() => handleClick('ReportSetting')} 
                    className={`cursor-default p-2 border-b hover:bg-slate-100 hover:font-medium ${activeMenu === 'ReportSetting' && 'bg-slate-100 font-medium'}`}>
                        اعدادات المستخدمين
                </li>
                <li 
                    onClick={() => handleClick('LanguagesSetting')} 
                    className={`cursor-default p-2 border-b hover:bg-slate-100 hover:font-medium ${activeMenu === 'LanguagesSetting' && 'bg-slate-100 font-medium'}`}>
                        اعدادات اللغة
                </li>
            </ul>
        </div>
    )
}

export default MeunSideSetting