export const FORM_TABS = [
    {
        label: 'التكلفة والتسعير',
        value: 'tabPrice'
    },
    // {
    //     label: 'المعلومات الاضافية',
    //     value: 'tabAddionalInfo'
    // },
    {
        label: 'روابط الاشتراك',
        value:'tabLinks'
    },{
        label:'الضبط والاعدادات',
        value: 'tabSettings'
    }
]

export const MONTH_LIST = [
    {
        label:'لا يوجد',
        value: 0,
    },
    {
        label:'شهر',
        value: 1,
    },
    {
        label:'شهرين',
        value: 2,
    },
    {
        label:'3 اشهر',
        value: 3,
    },
    {
        label:'4 اشهر',
        value: 4,
    },
    {
        label:'5 اشهر',
        value: 5,
    },
    {
        label:'6 اشهر',
        value: 6,
    },
    {
        label:'7 اشهر',
        value: 7,
    },
    {
        label:'8 اشهر',
        value: 8,
    },
    {
        label:'9 اشهر',
        value: 9,
    },
    {
        label:'10 اشهر',
        value: 10,
    },
    {
        label:'11 شهر',
        value: 11,
    },
    {
        label:'12 شهر',
        value: 12,
    },
]