
import { VscDebugBreakpointConditionalUnverified } from "react-icons/vsc";

const MwSwitch = ({onChangeSwitch,custKey,isActive,setIsActive,label,withIcon=false}) => {

    const onClickSwitch = () => {
        setIsActive(!isActive);
        onChangeSwitch && onChangeSwitch({key:custKey , value:isActive})
    }

    return (
        <div className="flex gap-2 items-center justify-between text-sm text-slate-600">
            {label && <div className="flex ">
                {withIcon && <VscDebugBreakpointConditionalUnverified size={20}/>}
                <span>{label}</span>
                
                </div>}
            <div 
                onClick={() => onClickSwitch()} 
                className={`relative flex h-4 w-12 rounded-lg ${isActive ? 'bg-purple-400 justify-end' : 'bg-gray-50' }`}>
                <div className='h-4 w-4 rounded-full bg-purple-500'></div>
            </div>
        </div>
    )
}

export default MwSwitch