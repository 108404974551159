import React from 'react'

export const MembershipInfo = ({item}) => {
    console.log('Membership',item)
    return (
        <div className='w-full text-xs text-slate-700 p-3 bg-slate-100 rounded-3xl'>
            <div className='flex-1 flex justify-between items-center'>
                <div className='flex items-center  gap-2'>
                    <img src={item.pic_url || '/assets/images/new-employee.jpg'} alt={item.name_ar} className='max-w-32 border-4 border-slate-300 rounded-full' />
                </div>
                <div className='text-lg text-slate-500 font-bold py-2 '>{item.name_ar}</div>
                <div className='flex gap-3'>
                    <div className='flex flex-col items-center border-e px-6'>
                        <span>رقم العضوية  </span>
                        <span className='font-bold'>{item.membership_no}</span>
                    </div>
                    <div className='flex flex-col items-center  border-e px-6'>
                        <span>الحالة</span>
                        <span className='font-bold'>{item?.status_membership?.name_ar}</span>
                    </div>
                    <div className='flex flex-col items-center  border-e px-6'>
                        <span>المجموعة</span>
                        <span className='font-bold'>{item?.group_membership?.name_ar}</span>
                    </div>
                    <div className='flex flex-col items-center  border-e px-6'>
                        <span>رقم الجوال</span>
                        <span className='font-bold'>{item?.mobile }</span>
                    </div>
                    <div className='flex flex-col items-center   px-3'>
                        <span>البريد الالكتروني</span>
                        <span className='font-bold'>{item?.email || 'لا يوجد'}</span>
                    </div>
                </div>
                
            </div>
        </div>
    )
}
