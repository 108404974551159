import React from 'react'
import MembershipStatusItem from './MembershipStatusItem'
import DataListViewRow from '../ui/data-view/DataListViewRow'
import { MwButton } from '../ui'

const MembershipListItem = ({
    item,index,currentLangId,  isShowDate = true,
    isShowStatus = true,
    setMode,
    setSelectedIitem,
    target = "membership"
}) => {
    const onClickHandler = (item) => {
        if(target === 'subscriptions'){
            setSelectedIitem(item)
            setMode('subscriptions')
        }
    }
    return (
        <DataListViewRow onClick={()=> onClickHandler(item)}>
            {/* <div className='w-8 text-center'>{index+1}</div> */}
            <div className='w-20 text-center'>{item.membership_no}</div>
            <div className='flex-1'>{item[`name_${currentLangId}`]}</div>
            <div className='w-32 text-center'>{item.mobile}</div>
            {target === 'subscriptions' && <MwButton size="sm" type="secondary" onClick={()=> onClickHandler(item)} > ادارة الاشتراكات  </MwButton>}
            { isShowDate && <div className='w-32 text-center '>{item.created_at}</div> }
            { isShowStatus && <div className='w-20 text-center'><MembershipStatusItem statusId = {item.status_id} statusName={item.status_membership[`name_${currentLangId}`]}/></div>}
        </DataListViewRow>
    )
}

export default MembershipListItem