
import { Routes ,Route } from "react-router-dom";
import withTenant from './utils/withTenant';
import { useState } from "react";
import { useSelector } from "react-redux";

import NotFound from "./pages/NotFound";
import TokenRedirect from "./pages/TokenRedirect";
import Logout from "./pages/Logout";
import DashBoard from "./pages/DashBoard";

import HeaderNavBar from "./layout/HeaderNavBar";
import Footer from "./layout/Footer";
import MenuSide from "./layout/MenuSide";

import withGuard from "./utils/withGuard";
import { useTenant,useStartup } from "./hooks";
import { MwSpinner } from "./components/ui";

import Settings from "./pages/Settings";
import AddNewMembership from "./pages/memberships/AddNewMembership";
import MembershipsManagement from "./pages/memberships/MembershipsManagement";
import ManageSubscriptions from "./pages/Subscriptions/ManageSubscriptions";
import Subscription from "./pages/Subscriptions/Subscription";
import MembershipsSubscriptionsHome from "./pages/memberships-subscriptions/MembershipsSubscriptionsHome";
import CreateInvoice from "./pages/invoices/CreateInvoice";
import ShowInvoice from "./components/invoice/ShowInvoice";
import ManageInvoices from "./pages/invoices/ManageInvoices";
import Transactions from "./pages/transactions/Transactions";
import ManageInvoicesZatca from "./pages/invoices/ManageInvoicesZatca";

function App() {
  const [openMenu,setOpenMenu] = useState(false);
  const langState = useSelector((state) => state.lang);
  const {tenant,tenantUsername,loading:loadingTenant,companiesList,branchesList,tenantId,companySelected,branchSelected} = useTenant();
  const {startup} = useStartup();
  
  return (
    !loadingTenant && startup ? <div dir={langState?.value === 'ar' ? 'rtl' : 'ltr'} className="h-screen flex flex-col flex-between  ">
      <HeaderNavBar
        companySelected = {companySelected}
        branchSelected = {branchSelected}
        companiesList = {companiesList}
        branchesList = {branchesList}
        tenantUsername = {tenantUsername}
        tenant = {tenant}
        openMenu = {openMenu}
        setOpenMenu = {setOpenMenu}
      />
      <div className="bg-white flex  flex-1   m-auto  w-full">
        <MenuSide
          openMenu={openMenu}
          setOpenMenu={setOpenMenu}
          />
        <div className={`flex flex-col flex-1 px-3 py-1 pe-5   primary-bg-color ${openMenu ? 'ms-8 md:ms-0 ps-3' :'md:ms-10'} `}>
          <div className=" rounded-2xl bg-slate-50 min-h-[calc(100vh-100px)]">
            <Routes >
                <Route path="/"   element={ <DashBoard/>} />
                <Route path=":tenant/"   element={ <DashBoard/>} />
                <Route path=":tenant/dashboard"   element={ <DashBoard/>} />
                <Route path=":tenant/token/:token" element={<TokenRedirect/>} />
                <Route  path=":tenant/logout"  element={ <Logout/> } />
                <Route  path=":tenant/settings"  element={ <Settings/> } />
                <Route  path=":tenant/memberships"  element={ <MembershipsManagement/> } />
                <Route  path=":tenant/memberships/add"  element={ <AddNewMembership/> } />
                <Route  path=":tenant/subscriptions/add"  element={ <Subscription/> } />
                <Route  path=":tenant/subscriptions/"  element={ <ManageSubscriptions/> } />
                <Route  path=":tenant/memberships-subscriptions/"  element={ <MembershipsSubscriptionsHome/> } />
                <Route  
                path=":tenant/invoices/create/:membershipId/:subscriptionId"  
                element={  
                  <CreateInvoice 
                  tenantId = {tenantId}
                  tenantUsername ={tenantUsername}
                  companySelected = {companySelected}
                  branchSelected = {branchSelected}
                  /> } 
                />
                <Route path=":tenant/invoices/show/:id/:companyId/:branchId"   element={!loadingTenant && <ShowInvoice/> } />
                <Route path=":tenant/invoices"   element={<ManageInvoices/> } />
                <Route path=":tenant/transactions" element={<Transactions/>} />
                <Route path=":tenant/invoices-zatca"   element={<ManageInvoicesZatca/> } />
                <Route path="/notfound"  errorElement={<NotFound/> }  />
                <Route path="/*"  errorElement={<NotFound/> }  />
            </Routes>
          </div>
        </div>
      </div>
      <Footer/>
    </div> : <MwSpinner/>
  );


}

export default withTenant(withGuard(App));
// export default App;
