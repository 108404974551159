import React from 'react'
import MwInputText from './MwInputText'

import {PiPlusSquareLight,PiMinusSquareLight} from 'react-icons/pi'

const InputQty = ({label,disabled,value,onChange,onIncress,onDecress}) => {
    return (
        <>
            { label && 
                <label className='text-xs px-1 text-slate-400  text-center' >{label}</label>}
            <div 
                className='flex justify-center items-center  w-28 rounded'>
                <PiPlusSquareLight 
                    onClick={onIncress}
                    className=' bg-white px-1 h-8 rounded-s-lg border' size={24}/>
                <MwInputText 
                    // label={`${  label}`}
                    disabled = {disabled}
                    value={value} 
                    onChange={onChange}
                    classNameInput = ' border'
                    
                    />
                <PiMinusSquareLight
                    onClick={onDecress}
                    className='bg-white px-1 h-8 rounded-e-lg border'
                    size={24}/>
            </div>
        </>
    )
}

export default InputQty