import React,{useState,useEffect} from 'react'
import { CardHeaderWithActions, MwButton, MwSpinnerButton } from '../../components/ui'
import { useNavigate, useParams } from 'react-router-dom';
import SubscriptionForm from '../../components/Subscription/SubscriptionForm';
import { useCurrent, useFetch, usePost } from '../../hooks';
import useUploadFile from '../../hooks/useUploadFile';

const Subscription = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [formState,setFormState] = useState({
    subscriptionNo:'',
    nameAr: '',
    nameEn:'',
    notes: '',
    cost:'',
    price:'',
    ticketsCount:'',
    periodDays:'0',
    startPeriodByHour:'',
    endPeriodByHour:'',
    suspendTimes:'',
    suspendDays:'',
    suspendPrice:'',
  });
  const [subscriptionDays,setSubscriptionDays] = useState([]);
  const [AllowStopSubscription,setAllowStopSubscription] = useState(false);
  const [AllowWaiverSubscription,setAllowWaiverSubscription] = useState(false);
  const [AllowAddPeriodSubscription,setAllowAddPeriodSubscription] = useState(false);
  const [AllowRefundSubscription,setAllowRefundPeriodSubscription] = useState(false);
  const [activitySelected,setActivitySelected] = useState([]);
  const [periodMonthSelected,setPeriodMonthSelected] = useState();
  const [serviceTypeSelected,setServiceTypeSelected] = useState();
  const [serviceClassSelected,setServiceClassSelected] = useState();
  const [servicesTagList,setServicesTagList] = useState([]);
  const [errors,setErrors] = useState();
  const [branchesSelling,setBranchesSelling] = useState([]);
  const [branchesProviding,setBranchesProviding] = useState([]);
  const {currentTenantId,currentCompanyId,currentBranchId,currentBranches,currentLangId,userId} =useCurrent();
  
  // upload the membership image
  const allowImgTypes = ["image/png", "image/jpg", "image/jpeg", "image/gif"];
  const {location:selectedImage,loading:loadingUploadPic,handleUpload:handleImageUpload} = useUploadFile(allowImgTypes,'activedeer-subscriptions-pic','image')
  
  const {data:postResult,loading:postLoading,postData} = usePost();
  const {data:servicesTypesResult,loading:servicesTypesListLoading} = useFetch(`${process.env.REACT_APP_API_BASE_URL}/services-types`)
  const {data:servicesClassesResult,loading:servicesClassesListLoading} = useFetch(`${process.env.REACT_APP_API_BASE_URL}/services-classes`)
  const {data:activitiesResult,loading:activitiesLoading} = useFetch(`${process.env.REACT_APP_API_BASE_URL}/activities`);
  const {data:resultGenSubscriptionNo,loading:loadingGenSubscriptionNo} = useFetch(`${process.env.REACT_APP_API_BASE_URL}/subscriptions/gen-no/${currentTenantId}/${currentCompanyId}/${currentBranchId}`)


  const resetForm = () => {
    setFormState({
      subscriptionNo:'',
      nameAr: '',
      nameEn:'',
      notes: '',
      cost:'',
      price:'',
      ticketsCount:'',
      periodDays:'0',
      startPeriodByHour:'',
      endPeriodByHour:'',
      suspendTimes:'',
      suspendDays:'',
      suspendPrice:'',
    });
  }
  const validateForm = () => {
    setErrors({});
    let _errors = {};

    if(!formState?.nameAr){
        _errors  = {..._errors,nameAr:'يجب ادخال اسم العضو'};
        setErrors({..._errors})
    }
    if(!formState?.price){
        _errors  = {..._errors,price:'يجب ادخال السعر'};
        setErrors({..._errors})
    }

    if(!periodMonthSelected?.value && formState.periodDays === '0'){
      _errors  = {..._errors,periodDays:'يجب ادخال فترة',periodMonthSelected:'يجب ادخال فترة'};
      setErrors({..._errors})
    }

    if(_errors && Object.keys(_errors).length > 0 ){
        console.error(_errors);
        return true;
    }
    return false;
}

  const onSubmitHandler = () => {
    if(validateForm()){
      return;
    }
    const _data = {
      subscription_no : formState.subscriptionNo || null,
      tags : JSON.stringify(servicesTagList) || null,
      name_ar : formState.nameAr || null,
      name_en : formState.nameEn || null,
      notes : formState.notes || null,
      cost : formState.cost || null ,
      price : formState.price || null,
      tickets_count: formState.ticketsCount || null,
      period_month: periodMonthSelected?.value || null,
      period_days: formState.periodDays || null,
      start_time: formState.startPeriodByHour || null,
      end_time: formState.endPeriodByHour || null,
      activity_ids : JSON.stringify(activitySelected) || null,
      main_img_url: selectedImage || null,
      type_id : serviceTypeSelected?.value || null,
      class_id: serviceClassSelected?.value || null,
      branches_selling: JSON.stringify(branchesSelling) || null,
      branches_providing: JSON.stringify(branchesProviding) || null,
      available_days: JSON.stringify(subscriptionDays) || null,
      have_stop: AllowStopSubscription || false,
      have_waiver: AllowWaiverSubscription || false,
      have_add_period: AllowAddPeriodSubscription || false,
      have_refund: AllowRefundSubscription || false,
      suspend_times : formState.suspendTimes || null,
      suspend_days : formState.suspendDays || null,
      suspend_price: formState.suspendPrice || null,
      branch_level_id : 1,
      branch_id : currentBranchId,
      company_id : currentCompanyId,
      tenant_id : currentTenantId,
    }
    postData(`${process.env.REACT_APP_API_BASE_URL}/subscriptions`,_data)

  }

  useEffect(() => {
    if(postResult?.status === 1){ 
        resetForm()
        navigate(`/${params.tenant}/subscriptions`)
    }
  },[postResult]);

  useEffect( () => {
    if(resultGenSubscriptionNo){
      formState.subscriptionNo = resultGenSubscriptionNo.data
    }
  },[resultGenSubscriptionNo]);

  return (
    <div className='p-5'>
      <CardHeaderWithActions title={'اضف اشتراك جديد'}>
          <MwButton onClick={()=>navigate(`/${params.tenant}/subscriptions`)} inGroup={true} type='secondary'> ادارة الاشتراكات </MwButton>
          {postLoading ? <MwSpinnerButton/> : <MwButton onClick={onSubmitHandler} inGroup={true} type='saveBtn'>حفظ</MwButton>}
      </CardHeaderWithActions>
      { resultGenSubscriptionNo ? <SubscriptionForm
        errors ={errors}
        formState = {formState}
        setFormState = {setFormState}
        activitySelected = {activitySelected}
        setActivitySelected = {setActivitySelected}
        periodMonthSelected = {periodMonthSelected}
        setPeriodMonthSelected = {setPeriodMonthSelected}
        branchesSelling = {branchesSelling}
        setBranchesSelling = {setBranchesSelling}
        branchesProviding = {branchesProviding}
        setBranchesProviding = {setBranchesProviding}
        currentLangId ={currentLangId}
        serviceTypeSelected = {serviceTypeSelected}
        setServiceTypeSelected = {setServiceTypeSelected}
        serviceClassSelected = {serviceClassSelected}
        setServiceClassSelected ={setServiceClassSelected}
        servicesTagList = {servicesTagList}
        setServicesTagList = {setServicesTagList}
        currentBranches ={currentBranches}
        activities = {activitiesResult}
        activitiesLoading = {activitiesLoading}
        servicesTypesResult = {servicesTypesResult}
        servicesTypesListLoading = {servicesTypesListLoading}
        servicesClassesResult = {servicesClassesResult}
        servicesClassesListLoading = {servicesClassesListLoading}
        subscriptionDays = {subscriptionDays}
        setSubscriptionDays = {setSubscriptionDays}
        AllowStopSubscription = {AllowStopSubscription}
        setAllowStopSubscription = {setAllowStopSubscription}
        AllowWaiverSubscription = {AllowWaiverSubscription}
        setAllowWaiverSubscription = {setAllowWaiverSubscription}
        AllowAddPeriodSubscription = {AllowAddPeriodSubscription}
        setAllowAddPeriodSubscription = {setAllowAddPeriodSubscription}
        AllowRefundSubscription = {AllowRefundSubscription}
        setAllowRefundPeriodSubscription = {setAllowRefundPeriodSubscription}
        selectedImage = {selectedImage}
        loadingUploadPic = {loadingUploadPic}
        handleImageUpload = {handleImageUpload}
        /> : <MwSpinnerButton label='جاري تحضير البيانات...' />
        }
    </div>
  )
}

export default Subscription