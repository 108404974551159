import React from 'react'
import { DataListViewRow } from '../ui/data-view'
import { BadgeActive, MwButton } from '../ui'
import { Link, useParams } from 'react-router-dom'
import { FaFileInvoice } from "react-icons/fa6";
import { FaRegCircleStop } from "react-icons/fa6";
import { TbTransfer } from "react-icons/tb";
import { CiViewTimeline } from "react-icons/ci";

const MembershipsSubscriptionsListItem = ({item}) => {
    const {tenant} = useParams();
    console.log('subscription item => ',item)
    return (
        <DataListViewRow>
            <div className='w-20 text-center'>{item?.id}</div>
            {/* <div className='w-20 text-center'>{item?.subscription?.id}</div> */}
            <div className='w-60'>{item.subscription?.name_ar}</div>
            <div className='w-28'>{item.start_date}</div>
            <div className='w-28'>{item.end_date}</div>
            <div className='w-28 text-center '>
                
                <BadgeActive label={item?.status?.name_ar}  />
                </div>
            <div className='flex-1 flex gap-2'>
                <div className='flex flex-col justify-center items-center border-l px-2'>
                    <FaFileInvoice size={16} />
                    <Link className='pt-1 ' to={`/${tenant}/invoices/create/${item?.membership_id}/${item?.subscription?.id}`}>اصدار الفاتورة</Link>
                </div>
                <div className='flex flex-col justify-center items-center border-l px-2'>
                    <FaRegCircleStop size={16} />
                    <Link className='pt-1' to={`/${tenant}/subscriptions/stop/${item?.subscription?.id}`}> إيقاف الاشتراك</Link>
                </div>
                <div className='flex flex-col justify-center items-center border-l px-2'>
                    <TbTransfer size={16} />
                    <Link className='pt-1 ' to={`/${tenant}/subscriptions/waiver/${item?.subscription?.id}`}> تنازل عن الاشتراك</Link>
                </div>
                <div className='flex flex-col justify-center items-center border-l px-2'>
                    <CiViewTimeline size={16} />
                    <Link className='pt-1 ' to={`/${tenant}/subscriptions/show/${item?.subscription?.id}`}>عرض الاشتراك</Link>
                </div>
            </div>
        </DataListViewRow>
    )
}

export default MembershipsSubscriptionsListItem