import React from 'react'
import { MwInputText, MwSelector, MwSpinner, MwSpinnerButton, MwTextArea, TitleGroup } from '../../ui'

const FormPrivateInfo = ({
    currentLangId,
    errors,
    formState,
    setFormState,
    idTypesList,
    idTypeSelected,
    setIdTypeSelected,
    genderList,
    genderSelected,
    setGenderSelected,
    nationalitySelected,
    setNationalitySelected,
    nationalityList,
    nationalityListLoading

}) => {
    return (
        <div id='tab-1' className='px-3 flex flex-col gap-3'>
        <div>
            <TitleGroup>بيانات الهوية</TitleGroup>
            <div className='flex gap-2 justify-between'>
                <div className='flex-1'>
                    <MwSelector
                        label={`نوع الهوية`}
                        initalValue='أختيار نوع الهوية...'
                        dataType={`labelValue`}
                        _data={idTypesList}
                        withAddNew={false}
                        withSearch={false}
                        selectedItem={idTypeSelected}
                        setSelectedItem={setIdTypeSelected}
                        invalid={!!errors?.idTypeSelected}
                        invalidMsg={errors?.idTypeSelected}
                        />
                </div>
                <div className='flex-1'>
                    <MwInputText
                        label='رقم الهوية'
                        value={formState.idNo}
                        onChange={(e) => setFormState((state)=> ({...state,idNo:e.target.value}) )}
                        invalid = {!!errors?.idNo}
                        invalidMsg = {errors?.idNo}
                        />
                </div>

                <div className='w-20'>
                    <MwInputText
                        label='رقم النسخة'
                        value={formState.idIssueNo}
                        onChange={(e) => setFormState((state)=> ({...state,idIssueNo:e.target.value}) )}
                        invalid = {!!errors?.idIssueNo}
                        invalidMsg = {errors?.idIssueNo}
                        />
                </div>
            </div>
            <div className='flex gap-3 pt-2'>
                <div className='flex-1'>
                    <MwInputText
                        label=' تاريخ اصدار الهوية ميلادي'
                        inputType ='date'
                        value={formState.idIssueDate}
                        onChange={(e) => setFormState((state)=> ({...state,idIssueDate:e.target.value}) )}
                        invalid = {!!errors?.idIssueDate}
                        invalidMsg = {errors?.idIssueDate}
                        />
                </div>
                <div className='flex-1'>
                    <MwInputText
                        label=' تاريخ اصدار الهوية هجري'
                        inputType ='date'
                        value={formState.idIssueDateH}
                        onChange={(e) => setFormState((state)=> ({...state,idIssueDateH:e.target.value}) )}
                        invalid = {!!errors?.idIssueDateH}
                        invalidMsg = {errors?.idIssueDateH}
                        />
                </div>
                <div className='flex-1'>
                    <MwInputText
                        label=' تاريخ انتهاء الهوية ميلادي'
                        inputType ='date'
                        value={formState.idExpireDate}
                        onChange={(e) => setFormState((state)=> ({...state,idExpireDate:e.target.value}) )}
                        invalid = {!!errors?.idExpireDate}
                        invalidMsg = {errors?.idExpireDate}
                        />
                </div>
                <div className='flex-1'>
                    <MwInputText
                        label=' تاريخ انتهاء الهوية هجري'
                        inputType ='date'
                        value={formState.idExpireDateH}
                        onChange={(e) => setFormState((state)=> ({...state,idExpireDateH:e.target.value}) )}
                        invalid = {!!errors?.idExpireDateH}
                        invalidMsg = {errors?.idExpireDateH}
                        />
                </div>
            </div>
        </div>
        <div>
            <TitleGroup>البيانات الشخصية</TitleGroup>
            <div className='flex gap-3 justify-between'>
                <div className='flex-1'>
                    <MwInputText
                        label='  تاريخ الميلاد ميلادي'
                        inputType ='date'
                        value={formState.dob}
                        onChange={(e) => setFormState((state)=> ({...state,dob:e.target.value}) )}
                        invalid = {!!errors?.dob}
                        invalidMsg = {errors?.dob}
                        />
                </div>
                <div className='flex-1'>
                    <MwInputText
                        label='  تاريخ الميلاد هجري'
                        inputType ='date'
                        value={formState.dobH}
                        onChange={(e) => setFormState((state)=> ({...state,dobH:e.target.value}) )}
                        invalid = {!!errors?.dobH}
                        invalidMsg = {errors?.dobH}
                        />
                </div>
                <div className='flex-1'>
                    <MwSelector
                        label={`النوع`}
                        initalValue='أختيار النوع...'
                        dataType={`labelValue`}
                        _data={genderList}
                        withAddNew={false}
                        withSearch={false}
                        selectedItem={genderSelected}
                        setSelectedItem={setGenderSelected}
                        invalid={!!errors?.genderSelected}
                        invalidMsg={errors?.genderSelected}
                        />
                </div>
                <div className='flex-1'>
                    {
                        nationalityListLoading ? <MwSpinnerButton withLabel={false}/> :
                        <MwSelector
                            label={`الجنسية`}
                            initalValue='أختيار الجنسية...'
                            dataType={`labelValue`}
                            _data={nationalityList?.map(nat =>({
                                label: nat[`nationality_name_${currentLangId}`] ,
                                value: nat?.id,
                                lang:currentLangId
                            }))}
                            withAddNew={false}
                            selectedItem={nationalitySelected}
                            setSelectedItem={setNationalitySelected}
                            invalid={!!errors?.nationalitySelected}
                            invalidMsg={errors?.nationalitySelected}
                        />

                    }
                </div>
            </div>
        </div>
        <div>
            <TitleGroup>بيانات التواصل</TitleGroup>
            <div className='flex gap-3 justify-between'>
                <div className='flex-1'>
                    <MwInputText
                        label='رقم الجوال'
                        typeInput = 'number'
                        value={formState.mobile}
                        onChange={(e) => setFormState((state)=> ({...state,mobile:e.target.value}) )}
                        invalid = {!!errors?.mobile}
                        invalidMsg = {errors?.mobile}
                        />
                </div>
                <div className='flex-1'>
                    <MwInputText
                        label='البريد الالكتروني'
                        value={formState.email}
                        onChange={(e) => setFormState((state)=> ({...state,email:e.target.value}) )}
                        invalid = {!!errors?.email}
                        invalidMsg = {errors?.email}
                        />
                </div>
                <div className='flex-1'>
                    <MwInputText
                        label='العنوان'
                        value={formState.address}
                        onChange={(e) => setFormState((state)=> ({...state,address:e.target.value}) )}
                        invalid = {!!errors?.address}
                        invalidMsg = {errors?.address}
                        />
                </div>
            </div>
        </div>
        <div>
            <TitleGroup>ملاحظات</TitleGroup>
            <div className='flex gap-3 justify-between'>
                <div className='flex-1'>
                    <MwTextArea
                        // label='ملاحظات'
                        rows={5}
                        value={formState.notes}
                        onChange={(e) => setFormState((state)=> ({...state,notes:e.target.value}) )}
                        invalid = {!!errors?.notes}
                        invalidMsg = {errors?.notes}
                        />
                </div>
            </div>
        </div>


    </div>
    )
}

export default FormPrivateInfo