import React from 'react'

const DataListViewRow = ({children,onClick =()=>{}}) => {
    return (
        <div onClick={onClick} className='flex cursor-default gap-3 items-center justify-between bg-slate-100 p-2 border-b hover:bg-slate-200 '>
            {children}
        </div>
    )
}

export default DataListViewRow