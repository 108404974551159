// Convert Array to Object
export const arrayToObjKeyValue = (array) => array && array.reduce( (obj,item) => {
    obj[item.name] = item.value;
    return obj;
},{});

export const LangArrayToObjKeyValue = (array) => array && array.reduce( (obj,item) => {
    obj[item.lang] = item.text;
    return obj;
},{});



//////////////
export const tenant = () => JSON.parse( localStorage.getItem('tenant'));
export const languagesCode =  ()=> tenant().languages?.map((language) =>  language.langCode )
export const generlSettings = () => tenant()?.setting?.generalSettings;
export const  AppSettings =  () => tenant()?.setting?.appSetting;
export const user = () => JSON.parse( localStorage?.getItem('user'));

// Tenant Companies
export const tenantCompaniesByLang = (lang) => {
    return tenant()?.companies?.map( company => { 
            return {
                companyId:company._id ,
                companyName: arrayNameByLang(company.name,lang)
            }
        });
}
export const BranchesByCompanyId = (companyId) => tenant()?.companies?.filter( (company) => company._id === companyId)[0]?.branches;

export const getCompanyById = (id) => tenant()?.companies?.filter(company => company._id === id)[0]


// Language Text Object
export const arrayNameByLang = (arrayName,lang) => {
    return  arrayName && arrayName.filter( name => name.lang === lang)[0] ;
}
export const langObjType = () =>{
    return languagesCode().reduce((k,v)=> ({...k,[v]:''}),{})
}
export const objLangTextHandler = (textObj) =>{
    let textHandler = [];
    for( const [key,value] of Object.entries(textObj)){
        textHandler.push( {
            text : value ,
            lang : key
        })
    }
    return textHandler
}

// General Settings
export const getGeneralSettings =  (settingName) => generlSettings()?.filter(setting => setting.name === settingName)[0]?.value 
export const defaultLang = getGeneralSettings('DEFAULT_LANG') || 'en';

// Invoice App Settings
export const invoiceAppSettings = () => AppSettings()?.filter(setting => setting?.app?.name === 'Invoices')[0];
export const getValueInvoiceAppSettings = (optionName) => invoiceAppSettings()?.setting?.filter(item => item.name === optionName)[0]?.value

export const printableDiv = (divPrintRef,lang='ar') => {
    const content = divPrintRef.current.innerHTML;
    const dir = lang === 'ar' ? 'rtl' : 'ltr';
    const printWindow = window.open('', '_blank');
    printWindow.document.write('<html><head>'+document.head.innerHTML+'</head><body dir="'+ dir + '" style="padding:5px;" >' + content + '</body></html>');
    setTimeout(() => {
        printWindow.print();
        printWindow.close();
        printWindow.document.close();
    }, 1000);
    // printWindow.onafterprint = function() {    
    // };
}

export const getDateNow = () => {
    var d = new Date();
    var local = d.getTime();
    var offset = d.getTimezoneOffset() * (60 * 1000);
    var utc = new Date(local + offset);
    var riyadh = new Date(utc.getTime() + (3 * 60 * 60 * 1000));
    return riyadh;
}