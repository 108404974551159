import React from 'react'
import { MdOutlineDelete } from "react-icons/md";


const SubScriptionsListItem = ({item,setSelectedItem,setShowDeleteConfirm}) => {
    const tags = JSON.parse(item.tags) || [];

    const confirmDeleteHandler = (item) =>{
        setSelectedItem(item);
        setShowDeleteConfirm(true);
    }
    return (
        <div key={item.id} className={`h-80 flex flex-col justify-between rounded-tl-3xl rounded-br-3xl bg-slate-200 hover:opacity-75 cursor-default  text-slate-600 bg-cover	 bg-[url(${ item.main_img_url || 'https://4sharks-absat.s3.eu-north-1.amazonaws.com/activedeer-subscriptions-pic/1716630241292-3f682960-fd35-4cf9-b31e-fd9208b3a0da.jpg'})]`}>
            <div>
                <div className='flex justify-between  p-3 text-sm '>
                    <div>
                        <span>{item.subscription_no}</span>
                    </div>
                    <div>{item.is_active ? 'مفعل' : 'غيرمفعل'}</div>
                </div>
                <hr className='border-slate-300 mb-2' />
                <div className='flex justify-between items-center'>
                    <div className='px-3 py-1 bg-slate-300 rounded-e-lg flex flex-col items-center jusutify-center'>
                        <span className='text-xs'>مدة الاشتراك</span>
                        <div className='px-2'>
                            {item.period_month > 0 && <span> {item.period_month} شهر </span> }
                            {item.period_days > 0 && <span> {item.period_days} ايام </span> }
                        </div>
                    </div>
                    <div className='px-3 py-1 bg-slate-300 rounded-s-lg flex flex-col items-center jusutify-center'>
                        <span className='text-xs'> سعر الاشتراك </span>
                        <div className='font-bold text-end px-2  '>{item.price} ر.س</div>
                    </div>
                </div>
            </div>

            <div className='font-bold bg-slate-300 opacity-75 text-center  p-3'>{(item.name_ar).substring(0,40)}</div>
            {/* <div className='py-3 '>
                <img src={item.main_img_url || "https://4sharks-absat.s3.eu-north-1.amazonaws.com/activedeer-subscriptions-pic/1716630241292-3f682960-fd35-4cf9-b31e-fd9208b3a0da.jpg"} className='w-full  ' alt={item.name_ar} />
            </div> */}
            <div>
                <div className='text-xs px-3 flex flex-col gap-1 '>
                    <span className='bg-slate-300 w-fit px-2' > عدد ايام الايقاف المسموحة <span className='font-bold'>{item.suspend_days || '0'}</span> يوم </span>
                    <span className='bg-slate-300 w-fit px-2' > عدد مرات الايقاف المسموحة <span className='font-bold'>{item.suspend_times || '0' }</span> مرة </span>
                    <span className='bg-slate-300 w-fit px-2' > عدد التذاكر <span className='font-bold'>{ ( !item.tickets_count || item.tickets_count === 0 )  ? 'غير محدود' : item.tickets_count}</span> { (item.tickets_count || item.tickets_count !== 0 ) && item.tickets_count &&  'تذاكر'  }</span> 
                </div>
                <div className=' flex gap-1 items-center text-xs mt-2 px-3 ' >
                    {
                        tags.map((tag,index) => <span key={index} className='bg-slate-300 px-2  rounded-lg' >{tag}</span>)
                    }
                </div>
                <div className='flex gap-3 justify-end p-2'>
                    <MdOutlineDelete size={21} onClick={()=>confirmDeleteHandler(item)} className='hover:text-red-700' />
                </div>
            </div>
            
        </div>
    )
}

export default SubScriptionsListItem