import React from 'react'
import { CiFilter } from "react-icons/ci";
import {  MwInputText,TagList,MwSelector, MwButton } from '../ui';
import {genderList} from '../../constants/membership'

const MembershipsFilter = ({
    currentLangId,
    filterState,
    setFilterState,
    tagsList,
    setTagsList,
    membershipGroupsResult,
    membershipGroupsLoading,
    membershipStatusResult,
    membershipStatusLoading,
    nationalityResult,
    nationalityLoading,
    nationalitySelected,
    setNationalitySelected,
    statusSelected,
    setStatusSelected,
    groupSelected,
    setGroupSelected,
    filterHandler

}) => {

    return (
        <div className='py-5 flex-1 flex  flex-col gap-3  items-start '>
            <div className='flex justify-center items-center gap-1 text-slate-500 text-sm'>
                <CiFilter size={18}/>
                <span className=''>التصفية</span>
            </div>
            <div className='w-full'>
                <MwInputText
                    inputType='date'
                    label='من تاريخ'
                    value={filterState.startDate}
                    onChange={(e)=>setFilterState({...filterState,startDate:e.target.value})}
                    />
            </div>
            <div className='w-full'>
                <MwInputText
                    inputType='date'
                    label='الي تاريخ'
                    value={filterState.endDate}
                    onChange={(e)=>setFilterState({...filterState,endDate:e.target.value})}
                    />
            </div>
            <div className='w-full'>
                <TagList
                    label={` Tags / العلامات`}
                    withLabel={true}
                    tagList={tagsList}
                    setTagList={(value)=>setTagsList(value)}
                    />
            </div>
            <div className='w-full relative z-30'>
                <MwSelector
                    label={`حالة العضو`}
                    initalValue={`عرض الجميع`}
                    _data={membershipStatusResult?.data?.map(item => ({
                        label: item[`name_${currentLangId}`],
                        value: item.id,
                        lang: currentLangId
                    }))}
                    selectedItem={statusSelected}
                    setSelectedItem={setStatusSelected}
                    dataType={`labelValue`}
                    withSearch={false}
                    withAddNew={false}
                    />
            </div>
            <div className='w-full  relative z-20'>
                <MwSelector
                    label={`مجموعة العضو`}
                    initalValue={`عرض الجميع`}
                    _data={membershipGroupsResult?.data?.map(item => ({
                        label: item[`name_${currentLangId}`],
                        value: item.id,
                        lang: currentLangId
                    }))}
                    selectedItem={groupSelected}
                    setSelectedItem={setGroupSelected}
                    dataType={`labelValue`}
                    withSearch={false}
                    withAddNew={false}
                    />
            </div>
            <div className='w-full  relative z-10'>
                { 
                    !nationalityLoading && <MwSelector
                    label={`الجنسية`}
                    initalValue={`عرض الجميع`}
                    _data={nationalityResult?.data?.map(item => ({
                        label: item[`nationality_name_${currentLangId}`],
                        value: item.id,
                        lang: currentLangId
                    }))}
                    selectedItem={nationalitySelected}
                    setSelectedItem={setNationalitySelected}
                    dataType={`labelValue`}
                    // withSearch={false}
                    withAddNew={false}
                    />
                }
            </div>

            <div className='w-full pt-5'>
                <MwButton type='secondary' onClick={filterHandler} classNameCustom='w-full' ><CiFilter size={18}/> عرض النتائج</MwButton>
            </div>
        </div>
    )
}

export default MembershipsFilter